import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/card/card.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const InternalContent = makeShortcode("InternalContent");
const ExternalContent = makeShortcode("ExternalContent");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/card" storybook="https://workday.github.io/canvas-kit/?path=/story/components-containers-card--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=19740-8458&t=gwsyYMobzrKIOcBB-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/644b01156dc5376e8952aa47bd608c6d/536c7/component-anatomy-card.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "24.324324324324326%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Card with annotation markers.",
              "title": "Image of a Card with annotation markers.",
              "src": "/static/644b01156dc5376e8952aa47bd608c6d/50383/component-anatomy-card.png",
              "srcSet": ["/static/644b01156dc5376e8952aa47bd608c6d/1efb2/component-anatomy-card.png 370w", "/static/644b01156dc5376e8952aa47bd608c6d/50383/component-anatomy-card.png 740w", "/static/644b01156dc5376e8952aa47bd608c6d/536c7/component-anatomy-card.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Card Container`}</strong>{`: This rectangular container is the fundamental part of this component. It is
styled with Canvas Tokens to ensure the background color, corner radius, depth, and outline
stroke are coherent as a basis with cards within workday. This can be used as the basis for
creating more tailored card types.`}</li>
        <li parentName="ol"><strong parentName="li">{`Title (Optional)`}</strong>{`: The title explained`}</li>
        <li parentName="ol"><strong parentName="li">{`Paragraph (Optional)`}</strong>{`: The Paragraph explained`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <InternalContent mdxType="InternalContent">
        <ul>
          <li parentName="ul">{`Cards hold a variety of content types, such as a combination of text, icons, imagery and actions
related to a single topic.`}</li>
          <li parentName="ul">{`Check out the
`}<a parentName="li" {...{
              "href": "https://www.figma.com/file/ZmCrRLFazOGuJzKxLdi3Aw/%F0%9F%9B%A0-Card-Framework?node-id=76%3A0"
            }}>{`Card Framework Figma library`}</a>{`
created for the UI Platform framework for design guidance on how to layout and style your cards.`}</li>
          <li parentName="ul">{`Cards should be easy to digest, providing relevant information and available actions.`}</li>
          <li parentName="ul">{`Text and visual elements should be placed in a way that clearly indicates hierarchy.`}</li>
          <li parentName="ul">{`Cards should be placed inside a layout grid to help with alignment and sizing.`}</li>
          <li parentName="ul">{`Most cards are created and maintained by specific product teams. The Canvas card is a generic
container which you can leverage when creating new cards. Check out the
`}<a parentName="li" {...{
              "href": "https://workday.github.io/canvas-kit/?path=/story/components-containers-card--basic"
            }}>{`Canvas Kit card`}</a>{`
container to see what’s available in Canvas now.`}</li>
          <li parentName="ul">{`Need to create your own custom card for your product? Check out our
`}<a parentName="li" {...{
              "href": "/get-started/for-designers/customizing-canvas"
            }}>{`Customizing Canvas`}</a>{` section for guidance on
creating new components in keeping with Canvas.`}</li>
        </ul>
      </InternalContent>
      <ExternalContent mdxType="ExternalContent">
        <ul>
          <li parentName="ul">{`Cards hold a variety of content types, such as a combination of text, icons, imagery and actions
related to a single topic.`}</li>
          <li parentName="ul">{`Cards should be easy to digest, providing relevant information and available actions.`}</li>
          <li parentName="ul">{`Text and visual elements should be placed in a way that clearly indicates hierarchy.`}</li>
          <li parentName="ul">{`Cards should be placed inside a layout grid to help with alignment and sizing.`}</li>
          <li parentName="ul">{`Most cards are created and maintained by specific product teams. The Canvas card is a generic
container which you can leverage when creating new cards. Check out the
`}<a parentName="li" {...{
              "href": "https://workday.github.io/canvas-kit/?path=/story/components-containers-card--basic"
            }}>{`Canvas Kit card`}</a>{`
container to see what’s available in Canvas now.`}</li>
        </ul>
      </ExternalContent>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use Cards when you need to group information in a digestible form.`}</li>
        <li parentName="ul">{`Use Cards when you need to offer a short entry point that is linked to more detailed content or a
complex task.`}</li>
        <li parentName="ul">{`Use Cards to lay out single or multiple sets of related information in the same region of the
page. Cards may include an image, a text summary, pills, and actions. Cards typically have similar
widths, but heights should accommodate varying content.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`When you need to show unrelated content types or actions in a single container.`}</li>
        <li parentName="ul">{`When you need to show content in multiple columns.`}</li>
        <li parentName="ul">{`When you need to display content in a table format.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Any interactive elements in the Card must have a focus indicator that is highly visible against the
background and against the non-focused state. Refer to
`}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more information.`}</p>
      <p>{`Cards must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: Focuses interactive elements included in the card (e.g. buttons, links, inputs, selects,
etc.)`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Cards must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`The title (heading) of a Card represents the beginning of the content in a card.`}</li>
        <li parentName="ul">{`Calls to action in a Card are uniquely distinguishable from other cards on the screen.`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <ul>
        <li parentName="ul">{`Decide heading level for the Card title in context of the page. Read more about
`}<a parentName="li" {...{
            "href": "/guidelines/accessibility/meaningful-page-structure"
          }}>{`meaningful page structure`}</a>{` for more information.`}</li>
        <li parentName="ul">{`Write accessible name for icon-only button variants. Read more about
`}<a parentName="li" {...{
            "href": "/guidelines/accessibility/alt-text#what-is-non-text-content"
          }}>{`non-text content`}</a>{`.`}</li>
        <li parentName="ul">{`Write unique accessible names for generic call to action buttons.`}</li>
        <li parentName="ul">{`Write text alternatives for images and illustrations, unless they are for decorative purposes
only.`}</li>
      </ul>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`Cards must begin with a heading element `}<inlineCode parentName="li">{`<h2>`}</inlineCode>{` - `}<inlineCode parentName="li">{`<h6>`}</inlineCode>{`.`}</li>
        <li parentName="ul">{`When using multiple cards together in a group, use `}<inlineCode parentName="li">{`<ul>`}</inlineCode>{` and `}<inlineCode parentName="li">{`<li>`}</inlineCode>{` elements to build card
containers as list items in an unordered list.`}</li>
        <li parentName="ul">{`An `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` string is required for icon-only buttons and accessible
`}<a parentName="li" {...{
            "href": "/components/popups/tooltip"
          }}>{`tooltips`}</a>{` can show the icon's name for everyone.`}</li>
        <li parentName="ul">{`Images, illustrations, and icons that may be considered decorative or redundant can be hidden from
screen readers by setting a null `}<inlineCode parentName="li">{`alt=””`}</inlineCode>{` attribute for `}<inlineCode parentName="li">{`<img>`}</inlineCode>{` elements.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Decorative `}<inlineCode parentName="li">{`<svg>`}</inlineCode>{` icons are hidden from assistive technology with
`}<inlineCode parentName="li">{`role=”presentation”`}</inlineCode>{` and `}<inlineCode parentName="li">{`focusable=”false”`}</inlineCode>{`.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`When writing content for Cards, refer to our `}<a parentName="li" {...{
            "href": "/guidelines/content/overview"
          }}>{`Content Style Guide`}</a></li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?type=design&node-id=10328-16305&mode=design&t=4YEmP5wB8niv6t6Z-4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/cab54f0a368f66b72c70fce9c98de1db/536c7/card-anatomy.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "59.189189189189186%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABEklEQVR42q1TAU7DMAzc/9/IxFampWWUUZo0cRL7cNoKKKq6IYh0ihzHJ58v2eGf124RiYxbzgyKaRPlzm3CeVFKsIOH82EVJUcxbhDK3GDTAdYjeoLTokGL11ByUbv8ruoH4XTI1Qvw3CF2FtYOU5crxCNhSvdLTsxakJEUgSJ8oAUKaVGB3kNOr4tOlx0+NEDdIV17HI8H7B/3cM7NU1lKk0EJ2x7y1G4QHi7TpV47MIRYR/BF3dRJjGi/ICxbpkxJOV9HQn5XKWdWkwRc626mnc2McpblDlNGyW/InVOpFiGRzjCgOlUwjdHnpHFUY8KAzPm2KUKpOAJWM4hUsr61Au89Qgifcckx/+Jh/+XrfQA3e7EeiXisnwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of the Card.",
                "title": "The basic elements of the Card.",
                "src": "/static/cab54f0a368f66b72c70fce9c98de1db/50383/card-anatomy.png",
                "srcSet": ["/static/cab54f0a368f66b72c70fce9c98de1db/1efb2/card-anatomy.png 370w", "/static/cab54f0a368f66b72c70fce9c98de1db/50383/card-anatomy.png 740w", "/static/cab54f0a368f66b72c70fce9c98de1db/536c7/card-anatomy.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol">
            <p parentName="li"><strong parentName="p">{`Card Container`}</strong>{`: Cards are empty containers with design tokens applied to them.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li"><strong parentName="p">{`Card Image (Optional)`}</strong>{`: Supporting image or media element`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li"><strong parentName="p">{`Card Header (Optional)`}</strong>{`: Commonly used for card titles`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li"><strong parentName="p">{`Card Body (Optional)`}</strong>{`: Main content area that can hold custom layouts and other components`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li"><strong parentName="p">{`Card Footer (Optional)`}</strong>{`: Mainly for actions or metadata`}</p>
          </li>
        </ol>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <p>{`Cards are empty containers with basic styling applied to them. They are highly composable and can
hold many types of content related to a single topic. Cards should summarize details about that
topic and generally be interactive, taking users to full details.`}</p>
        <h3 {...{
          "id": "cards-are-composable"
        }}>{`Cards are Composable`}</h3>
        <p>{`Cards are containers that hold related content and actions about a topic. They are highly composable
building blocks with any type of content, including other components, layouts, images, and steps.`}</p>
        <h3 {...{
          "id": "cards-are-actionable"
        }}>{`Cards are Actionable`}</h3>
        <p>{`Cards should be interactive and lead to more details about a topic.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Make the entire card a primary action with one large touch target." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/536c7/card-usage-actionable-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Card whose entire surface area is interactive.",
                    "title": "Card whose entire surface area is interactive.",
                    "src": "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/50383/card-usage-actionable-do.png",
                    "srcSet": ["/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/1efb2/card-usage-actionable-do.png 370w", "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/50383/card-usage-actionable-do.png 740w", "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/536c7/card-usage-actionable-do.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Avoid using Primary Buttons in cards." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/87a8fe784731c4c871601a0554ddf70d/536c7/card-usage-actionable-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Primary Button in a CardFooter",
                    "title": "Primary Button in a CardFooter",
                    "src": "/static/87a8fe784731c4c871601a0554ddf70d/50383/card-usage-actionable-dont.png",
                    "srcSet": ["/static/87a8fe784731c4c871601a0554ddf70d/1efb2/card-usage-actionable-dont.png 370w", "/static/87a8fe784731c4c871601a0554ddf70d/50383/card-usage-actionable-dont.png 740w", "/static/87a8fe784731c4c871601a0554ddf70d/536c7/card-usage-actionable-dont.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <p>{`A Card may have multiple tap targets in `}<inlineCode parentName="p">{`CardFooter`}</inlineCode>{`.`}</p>
        <p>{`Try to keep Cards focused and avoid crowding the Card with multiple actions`}</p>
        <h3 {...{
          "id": "cards-are-grouped"
        }}>{`Cards are Grouped`}</h3>
        <p>{`Cards are normally part of a larger collection of related cards. Isolated cards are rare. Make sure
Cards are modular - each card being independent of each other.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e70797cc838324765c7358edfc00f804/536c7/card-usage-group.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "45.94594594594595%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABsElEQVR42oXRS2sTYRTG8W7cuMlCUL+BqNn7NfoBFLqSrsWFQlSwtGKFJjsXxSKKWrtIrUUtCUrNQgtqGpPJZWY6MhMzMeOFycykEzqX/H1thBaqzYF3c87h93J4xthXQRAiywq2ZaFrLWzX3+0POFi9Xk/sysP5YG9jbP9SGASoqsKdQp77+QwY0wyMW6BPs6Xe5WZpmUW9BvI73NwCjdLHIRjH/wajOEL6XCWTGSe/coq1bJIPa2covz6LnDst+kmebUxBdgZvZhz5/ZvDwR0x0IubpOcmOLl0haP3UiQeXiOxOEXiQYr5J2mylWVYmaWXvoC8sT4KHNDcLHJp9iJHHqc4sXSdY09vcPzRVZILk5x7/orLhVVYvY2XPj8aDMKQrUaDSr3IC61M7ovES63CW6NKo1mmYP2iZn8n/qbR/rROrVz6G8r/QBGKoqq4dpef7Y5I+wdWs4XveMRRTBzsDBP2+9RUDalaPTzlP6CqqHS7XVqmidk2+Wq20A2DjtXB8TwRXIy/vY0sLpEkaTSoKAr9vo8tUFcAnniO4+C67i4UCzCMIvFZm3q9fgD8DTMch3JX0SupAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A Group of Cards",
                "title": "A Group of Cards",
                "src": "/static/e70797cc838324765c7358edfc00f804/50383/card-usage-group.png",
                "srcSet": ["/static/e70797cc838324765c7358edfc00f804/1efb2/card-usage-group.png 370w", "/static/e70797cc838324765c7358edfc00f804/50383/card-usage-group.png 740w", "/static/e70797cc838324765c7358edfc00f804/536c7/card-usage-group.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Cards should be modular, meaning each card can exist independently without relying on another card's
content.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Cards should be able to exist as separate modules." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/d5a111c6982ec7b3dce4dfc1774ef2c8/3cb0f/card-modular-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "50.54054054054055%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "A group of Cards related to design system topics.",
                    "title": "A group of Cards related to design system topics.",
                    "src": "/static/d5a111c6982ec7b3dce4dfc1774ef2c8/3cb0f/card-modular-do.png",
                    "srcSet": ["/static/d5a111c6982ec7b3dce4dfc1774ef2c8/1efb2/card-modular-do.png 370w", "/static/d5a111c6982ec7b3dce4dfc1774ef2c8/3cb0f/card-modular-do.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Avoid splitting content up between cards" mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/457a8e4eddc469b38a6f85dda2d20436/3cb0f/card-modular-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "51.08108108108108%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Two cards that break up content between themselves",
                    "title": "Two cards that break up content between themselves",
                    "src": "/static/457a8e4eddc469b38a6f85dda2d20436/3cb0f/card-modular-dont.png",
                    "srcSet": ["/static/457a8e4eddc469b38a6f85dda2d20436/1efb2/card-modular-dont.png 370w", "/static/457a8e4eddc469b38a6f85dda2d20436/3cb0f/card-modular-dont.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "cards-are-consumable"
        }}>{`Cards are Consumable`}</h3>
        <p>{`Cards should be easily identifiable when contrasted against the rest of the page, grouped by a
perceived boundary.`}</p>
        <p>{`Borders, negative space, depth, or background color to a Card are all acceptable ways to separate a
Card from the rest of the UI.`}</p>
        <h3 {...{
          "id": "cards-are-adaptive"
        }}>{`Cards are Adaptive`}</h3>
        <p>{`Card width and height should be adaptive to their context, alignment and sizing being determined by
their parent layout. Truncation rules should be set by feature teams based on the layout used.`}</p>
        <h4 {...{
          "id": "vstacks"
        }}>{`vStacks`}</h4>
        <p>{`Cards that are stacked on top of each other should prioritize card content instead of truncating
text. Cards by default span edge-to-edge for screen margins.`}</p>
        <p>{`As a rule of thumb, keep copy 3 lines or less avoid to overwhelming users.`}</p>
        <h4 {...{
          "id": "carousel-layout"
        }}>{`Carousel Layout`}</h4>
        <p>{`Cards in a carousel should truncate text content instead of growing the content to preserve the
baseline alignment of all cards in the same group.`}</p>
        <h3 {...{
          "id": "when-to-use-1"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`To group and present related information (icons, text, actions) in a digestible way.`}</li>
          <li parentName="ul">{`To provide an entry point to more details. Cards provide a summary rather than the full details
right away.`}</li>
          <li parentName="ul">{`To contain flexible layouts. Layouts may contain any type of content. Cards typically have similar
widths, but heights should accommodate varying content.`}</li>
          <li parentName="ul">{`To display variable content that is different between Cards. Cards are differentiated from one
another by their content.`}</li>
          <li parentName="ul">{`To build other card-like components, like an
`}<a parentName="li" {...{
              "href": "/components/popups/alert-dialog#tab=ios"
            }}>{`Alert Dialog`}</a>{`.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-1"
        }}>{`When to Use Something Else`}</h3>
        <ol>
          <li parentName="ol">{`Use `}<a parentName="li" {...{
              "href": "/components/text/text"
            }}>{`Text`}</a>{` if content cannot exist separately from other Cards. Don’t
force users to read card to card.`}</li>
          <li parentName="ol">{`Use a `}<a parentName="li" {...{
              "href": "/components/containers/list"
            }}>{`List`}</a>{` when users need to compare and contrast options against
each other.`}</li>
          <li parentName="ol">{`Use a `}<a parentName="li" {...{
              "href": "/components/buttons/button"
            }}>{`Button`}</a>{` for simple call to actions.`}</li>
          <li parentName="ol">{`Use a `}<a parentName="li" {...{
              "href": "/components/containers/table"
            }}>{`Table`}</a>{` for tabular content with multiple columns.`}</li>
          <li parentName="ol">{`If no action is provided on the card, consider if a Card needs to be used at all.`}</li>
        </ol>
        <h2 id="ios-examples">Examples</h2>
        <h3 {...{
          "id": "composition"
        }}>{`Composition`}</h3>
        <p>{`Cards are composed of four content slots that are used for different purposes. All content slots are
optional.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/27ce798bf0291c916c142abec4d7be7c/536c7/card-examples-composition.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "39.729729729729726%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA3ElEQVR42pWSv0sDQRCF83+rfQoTBRsrC20jKgZBTtNorQYkpEoT84Mk5Hb3duYuFn7urVikSGCLB495Mx8DMw1jHbvkbIF3fkt1bd9MY1dgXcGicIztmi+bB5no61qdJQFzY1GvdKbvHA67tEc9WqNnDob33Mz6lCFbh540oHiuPzccPSmt1w3HL1X0t4NvypAlA0WE7E04uzNcZZbLzETf+1BUfexJAjqt4LELp024OP/TSZOf7AFXVmnA/6PIYoWMJ+h0jk5m0ctyhS3CUVKBcdMAzb1sy+1/m1/P0FYPSpbREgAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Card with content slots labeled as Image, Header, Body, Footer",
                "title": "Card with content slots labeled as Image, Header, Body, Footer",
                "src": "/static/27ce798bf0291c916c142abec4d7be7c/50383/card-examples-composition.png",
                "srcSet": ["/static/27ce798bf0291c916c142abec4d7be7c/1efb2/card-examples-composition.png 370w", "/static/27ce798bf0291c916c142abec4d7be7c/50383/card-examples-composition.png 740w", "/static/27ce798bf0291c916c142abec4d7be7c/536c7/card-examples-composition.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "cardcontainer"
        }}>{`CardContainer`}</h4>
        <p>{`Card containers hold all card elements. Their size is determined by their content.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3a175341834d0b52aa0f0211e41ec62a/536c7/card-examples-container.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.83783783783784%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAALUlEQVR42mP4T2XAQHMD//37R5RGXOrIMhCkZmAN/Pv3L0FMtIHILsCH6RbLAKmNfTAZ1LEUAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Card Container without content",
                "title": "Card Container without content",
                "src": "/static/3a175341834d0b52aa0f0211e41ec62a/50383/card-examples-container.png",
                "srcSet": ["/static/3a175341834d0b52aa0f0211e41ec62a/1efb2/card-examples-container.png 370w", "/static/3a175341834d0b52aa0f0211e41ec62a/50383/card-examples-container.png 740w", "/static/3a175341834d0b52aa0f0211e41ec62a/536c7/card-examples-container.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "cardimage"
        }}>{`CardImage`}</h4>
        <p>{`By default, images render at the top of the card with an aspect ratio of `}<inlineCode parentName="p">{`{16/9}`}</inlineCode>{`.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ec6ad7d517437257d056548da3c0d948/536c7/card-media.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.83783783783784%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABPElEQVR42o2R6U4CQRCEef/n8aeJ0QQVvJCAIAgLe7AXyy57X8PnLEJCjAE7qZmkMl1dNd3ipMRuRy1OISjrmqKsqWvxAyEOvEDIN7+r1RxHOkwrPlcxcydFcVNUL0Pb5Bh+ibltUKH7BQs3wwnyM4IH3k9KnpWQvpYwNBLGVsaXKwU2tUTFfF2ieJXkmyG57LsgGEiHPTVmuEr3YlMnZ9EIGBH9ZcDL3GMuBzSitnRcydhnI0dZxcyKWa4z6aDYN/fVkKepy6vi05H3m7JhJp064T8Ek7xG83IpmLK0Ah5HBlcPI256Cp2JRXdiMtC2e+frqJJ/KC5EjgvaI4vrd53uWOdlanM3MLgfrbjtq7Q/dBm3ZOJkmOeWcqysqFCdEMNLsP0Y04tQLR/NDlAldDfEi0rsICVIij+X8g1yP2YadN4aEgAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Card with an image set to an Aspect Ratio 16/9",
                "title": "Card with an image set to an Aspect Ratio 16/9",
                "src": "/static/ec6ad7d517437257d056548da3c0d948/50383/card-media.png",
                "srcSet": ["/static/ec6ad7d517437257d056548da3c0d948/1efb2/card-media.png 370w", "/static/ec6ad7d517437257d056548da3c0d948/50383/card-media.png 740w", "/static/ec6ad7d517437257d056548da3c0d948/536c7/card-media.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><inlineCode parentName="p">{`CardImage`}</inlineCode>{` support custom aspect ratios.`}</p>
        <h4 {...{
          "id": "cardheader"
        }}>{`CardHeader`}</h4>
        <p><inlineCode parentName="p">{`CardHeader`}</inlineCode>{` is an optional sub-component. It mainly serves as a title to identify the Card and
defaults to `}<inlineCode parentName="p">{`h3`}</inlineCode>{`.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/23b78b5c9b43de2be588042c63d033df/536c7/card-anatomy-header.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAiUlEQVR42q2RQQrCMBREe/8juO0ZPIlYVFCJVMSmNptiTX4y4ze6qLtaHHiLP4vHwC/45xRfV0yE60lJrPdbuu7Gzjnaa5M7mJa8++lCDJ5YnykqXZYL7qoVzdHQNjaLcFLhEH5YOEp4RMbeUw4tkbR4AUU+TBZihErT5kJ4tYkWAW9ZnLFw7lOeAqnZDJbwd9MAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of CardHeader include a Title, Subtext or Body Text, and a leading Icon or Avatar.",
                "title": "The basic elements of CardHeader include a Title, Subtext or Body Text, and a leading Icon or Avatar.",
                "src": "/static/23b78b5c9b43de2be588042c63d033df/50383/card-anatomy-header.png",
                "srcSet": ["/static/23b78b5c9b43de2be588042c63d033df/1efb2/card-anatomy-header.png 370w", "/static/23b78b5c9b43de2be588042c63d033df/50383/card-anatomy-header.png 740w", "/static/23b78b5c9b43de2be588042c63d033df/536c7/card-anatomy-header.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Title`}</strong>{`: often communicates the subject of the card, such as the name of a feature area.`}</li>
          <li parentName="ol"><strong parentName="li">{`Subtext or Body Text(Optional)`}</strong>{`: Subtext are smaller text elements, such as a byline in an
article card. Body text includes body content, such as a snippet of an article.`}</li>
          <li parentName="ol"><strong parentName="li">{`Lead Area (Optional)`}</strong>{`: An `}<inlineCode parentName="li">{`AccentIcon`}</inlineCode>{` or `}<inlineCode parentName="li">{`Avatar`}</inlineCode>{` may be used at the start of `}<inlineCode parentName="li">{`CardHeader`}</inlineCode>{`.`}</li>
        </ol>
        <p><inlineCode parentName="p">{`CardHeader`}</inlineCode>{` may be swapped with a custom header.`}</p>
        <h4 {...{
          "id": "cardbody"
        }}>{`CardBody`}</h4>
        <p><inlineCode parentName="p">{`CardBody`}</inlineCode>{` serves as the primary content slot of the Card. This is where the main content should go.
Any type of content may be used in `}<inlineCode parentName="p">{`Card`}</inlineCode>{`.`}</p>
        <p>{`In general, try to limit the amount of content that goes into a Card, as it’s meant to be a preview.
One example could be to limit body text to 3 lines before truncating content.`}</p>
        <h4 {...{
          "id": "cardfooter"
        }}>{`CardFooter`}</h4>
        <p><inlineCode parentName="p">{`CardFooter`}</inlineCode>{` should be used for additional actions or metadata.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e9207607494314246ec0aa1a6fda0699/07d7d/card-examples-card-footer.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "38.91891891891891%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAdUlEQVR42r2RywqAIBBF+/+/lB5bm0DzQfc2WrkMF9HAwZE7cxAc+HEN/wh3RZSNhIBYUfuK3HmvELwEGJVZWVS28OoLE3jYo871CQHGGBlCYM6Z1loaY+icY0qpZWWuU8i2VE7vPUWk3R+6hc8r3+ot//yXTwqAelTwOAbbAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "CardFooter using a single secondary button and overflow menu",
                "title": "CardFooter using a single secondary button and overflow menu",
                "src": "/static/e9207607494314246ec0aa1a6fda0699/50383/card-examples-card-footer.png",
                "srcSet": ["/static/e9207607494314246ec0aa1a6fda0699/1efb2/card-examples-card-footer.png 370w", "/static/e9207607494314246ec0aa1a6fda0699/50383/card-examples-card-footer.png 740w", "/static/e9207607494314246ec0aa1a6fda0699/07d7d/card-examples-card-footer.png 1478w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Don’t use Primary Buttons in `}<inlineCode parentName="p">{`CardFooter`}</inlineCode>{`. Instead, the primary action should be placed on the
entire Card.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Place primary actions on the entire surface area of the card." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/536c7/card-usage-actionable-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "An interactive card.",
                    "title": "An interactive card.",
                    "src": "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/50383/card-usage-actionable-do.png",
                    "srcSet": ["/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/1efb2/card-usage-actionable-do.png 370w", "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/50383/card-usage-actionable-do.png 740w", "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/536c7/card-usage-actionable-do.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="A Primary Button placed in the Footer of a Card" mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/87a8fe784731c4c871601a0554ddf70d/536c7/card-usage-actionable-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "A Primary Button placed within the footer of the Card",
                    "title": "A Primary Button placed within the footer of the Card",
                    "src": "/static/87a8fe784731c4c871601a0554ddf70d/50383/card-usage-actionable-dont.png",
                    "srcSet": ["/static/87a8fe784731c4c871601a0554ddf70d/1efb2/card-usage-actionable-dont.png 370w", "/static/87a8fe784731c4c871601a0554ddf70d/50383/card-usage-actionable-dont.png 740w", "/static/87a8fe784731c4c871601a0554ddf70d/536c7/card-usage-actionable-dont.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <p>{`Only display one Secondary or Tertiary Button in `}<inlineCode parentName="p">{`CardFooter`}</inlineCode>{`. Any additional actions should be
placed in the Overflow `}<inlineCode parentName="p">{`Menu`}</inlineCode>{`.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/536c7/card-example-overflow.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.83783783783784%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAe0lEQVR42qWPSw6AIBBDuf8ZYc8OFUfCtzommCjGiDZp2DBtn8CNSim7v0g0YS+OngrF3WetNZRSCCE0RbQ4DNPUsXALdM6BiJBSQs755BjjXvR6IYsPrLXw3h941XEr4dCuhRwmpYQxpkGeacEwdiIzan2vyNVdyH+0AgcjemkiCoPtAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "CardFooter with one Secondary Button and an Overflow Menu.",
                "title": "CardFooter with one Secondary Button and an Overflow Menu.",
                "src": "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/50383/card-example-overflow.png",
                "srcSet": ["/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/1efb2/card-example-overflow.png 370w", "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/50383/card-example-overflow.png 740w", "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/536c7/card-example-overflow.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><inlineCode parentName="p">{`CardFooter`}</inlineCode>{` can be swapped with a custom footer component, if you'd like.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/288abfec9d9c3c22db60ad80c4c5fbc0/536c7/card-footer-custom.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.83783783783784%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA5ElEQVR42q1Sy66CMBD1/7f6Q6z07nXj4waCDx5SQQK0tJ3jFAFNNC6Mk0w67Zw5Z6btBD+2CRF1Qdu28DwPQRDAnSmlYK39nrCqa0xnM8wXf6gbiUzkLKK7nMPc3bKIgXkSGupHwiFwIJeUUqK4lrgURdf1YEmSYb0N4Psh4lMEWBbQXGPse0IpFfbHGMco4e4yxtND3RiIXYr/ZQyxTdGGZ+iDgA4Zd236VgfCvsjwiNJfQR02aCKB6iRgsnLEkhuT7h0RC5Du/WXkfk9lDYgzkF9g8goyzbt1vKsn//gov/42N9JSc2sv/CHOAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A custom CardFooter used in place of the default CardFooter.)",
                "title": "A custom CardFooter used in place of the default CardFooter.)",
                "src": "/static/288abfec9d9c3c22db60ad80c4c5fbc0/50383/card-footer-custom.png",
                "srcSet": ["/static/288abfec9d9c3c22db60ad80c4c5fbc0/1efb2/card-footer-custom.png 370w", "/static/288abfec9d9c3c22db60ad80c4c5fbc0/50383/card-footer-custom.png 740w", "/static/288abfec9d9c3c22db60ad80c4c5fbc0/536c7/card-footer-custom.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "overflow-menu"
        }}>{`Overflow Menu`}</h3>
        <p>{`Overflow `}<a parentName="p" {...{
            "href": "/components/popups/menu#tab=ios"
          }}>{`Menus`}</a>{` contain related actions on the Card. They are
typically placed in `}<inlineCode parentName="p">{`CardHeader`}</inlineCode>{` or `}<inlineCode parentName="p">{`CardFooter`}</inlineCode>{`.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/9e6f49d1a4a8af1f93bf8074f00331d9/536c7/card-usage-menu.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.83783783783784%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAApklEQVR42q2RSw7DIAxEc/9rRmqlLgghfBLbMMGoZVGpTaJ20Cyw0WMwA54qdckiSPeE7bGhTAWw6C6m9p20c980dGApiClivI0w1oCYel0Vas/MFkfqQL2YiRFDxJpWSBYI18QpwS++gbWf88mE71KAiMB7jxBCq+n+lfgyUJVzbjAi+g9QAWoFUn2u+ueEzjlYO2GuH6LzPP8pH+bIzM2aUtMeAXe1L3marzyI/gAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "An Overflow Menu in the header, expanded, with 3 actions.",
                "title": "An Overflow Menu in the header, expanded, with 3 actions.",
                "src": "/static/9e6f49d1a4a8af1f93bf8074f00331d9/50383/card-usage-menu.png",
                "srcSet": ["/static/9e6f49d1a4a8af1f93bf8074f00331d9/1efb2/card-usage-menu.png 370w", "/static/9e6f49d1a4a8af1f93bf8074f00331d9/50383/card-usage-menu.png 740w", "/static/9e6f49d1a4a8af1f93bf8074f00331d9/536c7/card-usage-menu.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "link"
        }}>{`Link`}</h3>
        <p>{`Cards should be interactive. They support the `}<inlineCode parentName="p">{`Link`}</inlineCode>{` and `}<inlineCode parentName="p">{`onTapGesture`}</inlineCode>{` properties. Both properties
can be passed to `}<inlineCode parentName="p">{`Card`}</inlineCode>{` so that the correct tag is created for screen readers.`}</p>
        <h4 {...{
          "id": "layouts--long-text"
        }}>{`Layouts & Long Text`}</h4>
        <p>{`Cards should be placed in a layout to control alignment & size. Truncation rules and line limits are
configurable based on the layout.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Truncate Card text to keep all Cards in the group at the same height." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/86e1b4b4dde0f1e801eea75730451263/536c7/card-layouts-carousel-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB4UlEQVR42l2RS28SYRiF+2+Mf8MYF8aFunDjQo02JrrQYNLG28I0uqpxYbqwG2SDl6hRAzEBgyZKb9g0iFgqaYGZYYCBYS7AwFwfB1pt9U3O7pzne8/7TTEZH6wy9XIapzxPtKhyPZEn8qlCZLnNta9Npr9oxEsqCwWV6bTIXGaLpc0KP3WHouGi2f6ENLXL87C7P6gUXjNcO8VMVuTQrbdEXuW5mVM5nSgy83yR2XWTo48/cOz8GW4/jfMumSKzniezoyCY9j4wcB2aQo3C9yzmyrkwqHL4wh1O3I9xb6PPwtIyD57dZXZV52T8G0+uHuHliyiJDYGPK3mSmc/Uur0DG3oO8naZQmmVoHSD+eKAS7EUVxIFLuYGnE13OP6mzaOiycNkltTcZWLRRd7/6rEmWuSENi3LOwAcb+n71BtNbNuia5hURQlRqlMRRORWi6HjYrvhrcw+QuhTVI2mohIEwZ74FzgeudnGCYOGYbBV2qRW3UGWpVB1LGuA6/k49gjPtbGGIxqtDv/P1AS9J7mhYI9sBoMhjfD1jtbDD/bNnuPQ7YfnMQOq7SFCXRl3+5vfBYZVCavgeUhSA1PTMc0QqLtsKyP0Xvh7njvx2JaFrhloeh+lrVGXmpPcn/wY+BtcnTwr/ownzgAAAABJRU5ErkJggg==')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Cards in a carousel should truncate text content instead of growing content to preserve the baseline alignment of all cards in the same group.",
                    "title": "Cards in a carousel should truncate text content instead of growing content to preserve the baseline alignment of all cards in the same group.",
                    "src": "/static/86e1b4b4dde0f1e801eea75730451263/50383/card-layouts-carousel-do.png",
                    "srcSet": ["/static/86e1b4b4dde0f1e801eea75730451263/1efb2/card-layouts-carousel-do.png 370w", "/static/86e1b4b4dde0f1e801eea75730451263/50383/card-layouts-carousel-do.png 740w", "/static/86e1b4b4dde0f1e801eea75730451263/536c7/card-layouts-carousel-do.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="A group of Cards in a carousel whose text does not truncate, creating a jagged baseline in the row." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/d37fd9ee4eb81cf9cc97321f9d4d49a2/536c7/card-layouts-carousel-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB4ElEQVR42m2QS2sTYRSG+2sE9+Je3Chida8bEUELCiIIiuJGQUiJC3fxAgqtRSspViwuWjWmUdNqYmvbpE3aZCaTyWWSzGQuSeaSp19awaC+cDh8cM7zvecdYaB+H7ollO0EneIEb/M64fgmoWSJ8ZTGg7TG3VSb9/kG03mD+ymdyJLEj22Foh2wY3qYXrCHGtkH+nitFMXVKXrJUa7FZQ7cnuXi6w3GvmgciWY5Oxnl+rLOscgHDl+4xOXIFNF388z/zLAgG0iW+wfY913qiszaShz96zlufdc4eP4eZ0IThFctHn9b5MnLK9xcMjjxNE547CivZl4wl8yykFhm7mOMYtMcciiAcnaDlfVF+CWAaZPjoUlGn33mVMzg5KzMoecF7qR1bswkeHP1NI8ejjOdsYjlDD5lJJRhh4MMfVdAFQXXrqKI39YKCllZZV3UltqkJrJqdT1UrclmbosdpYJUbdDzA3pBgN9nCPhb5UqdngA7tkVFkdAqZarlElW1jO+5uJ7I2u0R+B6dTpdKTeNv/QN0xYLR8SjpHjnNo+X44oKAgQHfE2/bR7Wg0Ogiqf8DCruIwUEpJRVTNzDbJrph02jZWKaN6zh0bQe73aatmxiGQ62u783j+/v7gw7sAmFVPB/UOWPkAAAAAElFTkSuQmCC')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Cards in a carousel whose text does not truncate, creating unequal height Cards in the same row.",
                    "title": "Cards in a carousel whose text does not truncate, creating unequal height Cards in the same row.",
                    "src": "/static/d37fd9ee4eb81cf9cc97321f9d4d49a2/50383/card-layouts-carousel-dont.png",
                    "srcSet": ["/static/d37fd9ee4eb81cf9cc97321f9d4d49a2/1efb2/card-layouts-carousel-dont.png 370w", "/static/d37fd9ee4eb81cf9cc97321f9d4d49a2/50383/card-layouts-carousel-dont.png 740w", "/static/d37fd9ee4eb81cf9cc97321f9d4d49a2/536c7/card-layouts-carousel-dont.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "size"
        }}>{`Size`}</h3>
        <p>{`Three size options are provided by default - `}<inlineCode parentName="p">{`small,`}</inlineCode>{` `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, and `}<inlineCode parentName="p">{`large`}</inlineCode>{`. Increases in size are
represented as increases in Card padding.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8158799aa02c091ae9772fdd8f01eee2/536c7/card-sizes.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "71.35135135135134%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABLklEQVR42q1Sy07DMBDs/38Qd9QTCMpD5UQAtSTQKs2zieP3sOuGcALFopZGHs9mN+vxLnDmtfgt0AqL8mjRDRaPmcZ9qgJnjWOzCvpxP3QWy5dh0ru+J4jpfJkMqISb36G2HpuDQtUZHLnDdMADgXlJ2rZQMNbHXfnUsg/gZP7J9znaQ07JyavP2qKg669SjdutCvyDNI75mII1+XOx7uGchzJUvGwIbeCscayO8dBQUrKTyAqNvDa4fhO4ehXIGxM0jvE38R6Oa9A+4F9zWJJf+8ai7i3u3hW9sg6ctaqzcQWFdnjKFCTt0ni0vUJDYD6Qts4k+ennD3bROyyTn8HWSgZMg/1MMyld3JV3tUYaHkXjZiOxorHJR21Pe7SHpxn2oW+hXLCBOWt/Pc8XJThM2TZFYd0AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Small, Medium, and Large Cards placed side by side for comparison.",
                "title": "Small, Medium, and Large Cards placed side by side for comparison.",
                "src": "/static/8158799aa02c091ae9772fdd8f01eee2/50383/card-sizes.png",
                "srcSet": ["/static/8158799aa02c091ae9772fdd8f01eee2/1efb2/card-sizes.png 370w", "/static/8158799aa02c091ae9772fdd8f01eee2/50383/card-sizes.png 740w", "/static/8158799aa02c091ae9772fdd8f01eee2/536c7/card-sizes.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}><strong parentName="th">{`Size`}</strong></th>
              <th parentName="tr" {...{
                "align": null
              }}><strong parentName="th">{`Token`}</strong></th>
              <th parentName="tr" {...{
                "align": null
              }}><strong parentName="th">{`Usage`}</strong></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`small`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`space.x3`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Cards using a horizontal layout`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`medium`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`space.x4`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Default cards, typically in a group`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`large`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`space.x5`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Large, standalone cards like a `}<inlineCode parentName="td">{`DialogCard`}</inlineCode></td>
            </tr>
          </tbody>
        </table>
        <h3 {...{
          "id": "custom-padding--spacing"
        }}>{`Custom Padding & Spacing`}</h3>
        <p>{`You may choose to overriding defaults with `}<inlineCode parentName="p">{`space`}</inlineCode>{` tokens. See the `}<a parentName="p" {...{
            "href": "/styles/tokens/space"
          }}>{`Space`}</a>{` article to
learn more.`}</p>
        <h3 {...{
          "id": "style"
        }}>{`Style`}</h3>
        <p>{`Three visual styles of Cards - `}<inlineCode parentName="p">{`Elevated`}</inlineCode>{`, `}<inlineCode parentName="p">{`Filled`}</inlineCode>{`,and `}<inlineCode parentName="p">{`Outlined`}</inlineCode>{`. There is no difference between
Card styles and their function.`}</p>
        <p>{`To determine what style to use, consider contrast requirements, and the consistent application of
style across a feature.`}</p>
        <h4 {...{
          "id": "elevated"
        }}>{`Elevated`}</h4>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/99eefb85069827f5d2004144757eb41b/536c7/card-style-elevated.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAJUlEQVR42mP4T2XAgEvi379/eDFJBuLTQEgN/QwkBpMUhuRGCgC4493uFbAUHwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Elevated Card with a depth (shadow) applied.",
                "title": "Elevated Card with a depth (shadow) applied.",
                "src": "/static/99eefb85069827f5d2004144757eb41b/50383/card-style-elevated.png",
                "srcSet": ["/static/99eefb85069827f5d2004144757eb41b/1efb2/card-style-elevated.png 370w", "/static/99eefb85069827f5d2004144757eb41b/50383/card-style-elevated.png 740w", "/static/99eefb85069827f5d2004144757eb41b/536c7/card-style-elevated.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Use `}<inlineCode parentName="p">{`depth`}</inlineCode>{` to show or hide an elevation on the card.`}</p>
        <p>{`When depth is set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, the `}<inlineCode parentName="p">{`border`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`false`}</inlineCode>{`. Don’t use both depth and border on a
card.`}</p>
        <h4 {...{
          "id": "filled"
        }}>{`Filled`}</h4>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/457e9c3e54f13105803452b90e13c624/536c7/card-style-filled.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAQElEQVR42mP48PHTf2z4/YePeDEufQyYBn36//nL1/8/f/7Ci0FqiDTw4/9v33/8JwS+ff+O1aX0MJDKXqY0UgBcvs7aErEZEQAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A white filled card with no depth or border applied, against a light grey background",
                "title": "A white filled card with no depth or border applied, against a light grey background",
                "src": "/static/457e9c3e54f13105803452b90e13c624/50383/card-style-filled.png",
                "srcSet": ["/static/457e9c3e54f13105803452b90e13c624/1efb2/card-style-filled.png 370w", "/static/457e9c3e54f13105803452b90e13c624/50383/card-style-filled.png 740w", "/static/457e9c3e54f13105803452b90e13c624/536c7/card-style-filled.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Filled Cards have default their background color to `}<inlineCode parentName="p">{`frenchVanilla100`}</inlineCode>{`. Filled cards do not have a
`}<inlineCode parentName="p">{`border`}</inlineCode>{` or `}<inlineCode parentName="p">{`depth`}</inlineCode>{` applied by default.`}</p>
        <p>{`The `}<inlineCode parentName="p">{`color`}</inlineCode>{` parameter can be used to change the background color of the card to something else.`}</p>
        <p>{`In general, avoid changing the background color of Cards unless it's necessary for contrast or
feature requirements.`}</p>
        <h4 {...{
          "id": "outlined"
        }}>{`Outlined`}</h4>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ac5903fe2d84d34fb1834679d1c4d242/536c7/card-outline.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAIUlEQVR42mP4T2XAgE3w379/RGGSDCQEBt5AqnqZkkgBALBV3ejd7BHdAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Outlined Card with a border applied to its container",
                "title": "Outlined Card with a border applied to its container",
                "src": "/static/ac5903fe2d84d34fb1834679d1c4d242/50383/card-outline.png",
                "srcSet": ["/static/ac5903fe2d84d34fb1834679d1c4d242/1efb2/card-outline.png 370w", "/static/ac5903fe2d84d34fb1834679d1c4d242/50383/card-outline.png 740w", "/static/ac5903fe2d84d34fb1834679d1c4d242/536c7/card-outline.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`A border may be used instead of depth. Outlined cards should not have a depth but may have a
background color.`}</p>
        <h3 {...{
          "id": "images"
        }}>{`Images`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0d73c20653948a54355af4a43a4147fc/536c7/card-examples-media.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "43.24324324324324%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABa0lEQVR42q2RO0tCARTH/QANDbVVEA19gIyoKade5N4QEZQ2FFFR0YsuRaIhJtZQloQkaQ8oCoxSkjTEkiwJaooeKF6om+bzSt77z65DDzQd+sOPc4Zzfhw4PPwKy7KI0DRCHHGE4inCyT4aoxHjiHFz6cL7LvqM94WCYJyAYHoGTQoFhCoV6pNUSWZRPkqgpKMH/PYueEjyx15GIflKQTQnw7BaCYleDYlhGWLJJFqlUxAq5SgeGESBqBt3vpSQySYMBAPQbmqwY1yH1WHEknYelTXV6B3pg/5oG4RmAWKlAl6Kyu3CcICCSSfFyZ4Kt64t6NbkyMsvxATRD/fVAYz7q9g1qBEM+LMJGa6+kU/QtvGx0VkBG1GLQ1kzikrLsDjUgJuVFhwTdTCPNSLy7MntQpqO4cJuw/WpBQ/nFtw7LbA7zvB4aYHfbQLpNMPnsuKdjv795f9KWiHLMBwMk+BIJJI1Cct8kUn4AT+zgSlNk2HaAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Card with an Image at the top",
                "title": "Card with an Image at the top",
                "src": "/static/0d73c20653948a54355af4a43a4147fc/50383/card-examples-media.png",
                "srcSet": ["/static/0d73c20653948a54355af4a43a4147fc/1efb2/card-examples-media.png 370w", "/static/0d73c20653948a54355af4a43a4147fc/50383/card-examples-media.png 740w", "/static/0d73c20653948a54355af4a43a4147fc/536c7/card-examples-media.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`A supporting visual can be used to make it easier to for users to identify a Card.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Images should match the topic." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/b6b095e90b471d65f9afbc524099a3f9/536c7/card-images-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABX0lEQVR42o2SXSuDARTHfQnlbl4ijG1NkwsyrbUaaUnJhdz4AC7mAiXJDUVeRoSsWKktteQCxc24WLaHZtYeb6PULjbhSnu256dnUt6Gf53O6XT+v86pk8cXSWmJiHjOSUjAf+LnMnbFS0ri+fGJu5tbhEAQISggSRI/Ke+9yGQy2SxeRDA0atBbqqm16HB5Ntje87HsXMfW1kxRhYpqgxpRjH7y5QSGo2HUDSp01lJau5oYHehlccXFsGOOGksZVcYSNCYtkcvIf4Hn6M1qdMZyTO31jPR3MzTQh+cgwJhzlSablo6eemL3F28++Q/gWTSEylBAYWUxVlsLU+N2hgbtzK95ce0EMHeaqDPmc3X9x4ayLGfzw2OS2ZUZJqYncW96OPLtcujb5/g0TEiM4d7ysrDkIJlIfPJ9A34EJxMPxONxckmZ+Qr6FZhOp7OnpFKp7HsoofSUUGqlnwv4CthTKJKPemiZAAAAAElFTkSuQmCC')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image paired with a matching title",
                    "title": "Image paired with a matching title",
                    "src": "/static/b6b095e90b471d65f9afbc524099a3f9/50383/card-images-do.png",
                    "srcSet": ["/static/b6b095e90b471d65f9afbc524099a3f9/1efb2/card-images-do.png 370w", "/static/b6b095e90b471d65f9afbc524099a3f9/50383/card-images-do.png 740w", "/static/b6b095e90b471d65f9afbc524099a3f9/536c7/card-images-do.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Avoid decorative imagery. Visuals should imply the subject of the card." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/e54f8ad4afb9a8f3997afd6761fd2f6d/536c7/card-images-don't.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABLUlEQVR42o2Ri0oCYRBGfdYeo54hgqAwSDTBQDMEjSSw0FoI8laUpuZ6CXXVXS+pu17Y9aSmUKLpB4f5YWYODL+JpRjGGKU1oD6h2tQmVaPZmdJHaWhUKio1WWM8HrMqpsVj0W91Bzj8ec79RU59KSz+NM5gAf+TiO8hi+suy2W4QLs//LO3QvjT6aoqzps0Nu8nZ1cZ3ME3Dk8yWB0FArkiF4kqjmeZRn+0nXA46nEtJLB4SxNZHuE+gN0exeyUcCcVzFaZY3MdpbelsKOpuEIf2EISNkHhNh7BI8TwPIY5OIqwtyOyv1uiXtt08rxqA52YWCOWl3mtDsl0NaS2hFyOk3oXiSZ7vMQ11C/9f+HvSJUyxZzIukyv2fjLy8NTdMOYo2PoOvoMY8Y64TfIMFrfwp3dnQAAAABJRU5ErkJggg==')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image paired with a non-matching title\"",
                    "title": "Image paired with a non-matching title\"",
                    "src": "/static/e54f8ad4afb9a8f3997afd6761fd2f6d/50383/card-images-don't.png",
                    "srcSet": ["/static/e54f8ad4afb9a8f3997afd6761fd2f6d/1efb2/card-images-don't.png 370w", "/static/e54f8ad4afb9a8f3997afd6761fd2f6d/50383/card-images-don't.png 740w", "/static/e54f8ad4afb9a8f3997afd6761fd2f6d/536c7/card-images-don't.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "layout"
        }}>{`Layout`}</h3>
        <h4 {...{
          "id": "carousels"
        }}>{`Carousels`}</h4>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Truncate header or body text for cards that should maintain a fixed height, such as a collection of cards in a carousel." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/1499dcb26a2c417a560915485c40242e/536c7/card-carousel-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "43.24324324324324%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABm0lEQVR42o2RTUsbURSG/UH+iK7ddtdNoVAoFfoDulFcFLqoUIq0UC0oVmtLSfEjCxVqUrrQShTJx5jRmUlmJvOlbWYmmQ8T8/SOi5KFmB54ec8997wPXO4YogaDQWZIkiQkc3RyRq3e5E/YE+oLXeN3YoK0jx/3iPqgGhaqqjKcz2pseHB6KlMsFVDLC1xqefzGOt1mjisjhyMf0djNYRe+cvlzg3L+E6qm3Q2sVGXKh8tcm+/EKQXzA1c/xnm1MMP+Vp7S0wc4z+9hzD5Gf/0EpdG8G6hLdaZ3vjCx/p4XJwVWz3+xrxWZmFNYWT3EePaQxdn7zKxNk7yd5GwUUBHA+dIej4ofmfy+wtTxDm/kA15u7bG5XUf//I2N/BxrB5sE20ucj35yFcd2SP2Qtu2RdLr/AmkS47V/Y7dckqCD7bkoijIaaDnOzbLtOhgtE/figrTXpxuneJ6HZVl4YlYTuyN/uVKpYmcBAbVMAcvgrRadMCSJEzzXxdT1m3upVvs/oGmYRN2IwA8I2j6h8HCozzyOIjQBuw34F61+k+3QoKfnAAAAAElFTkSuQmCC')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Truncate header or body text for cards that should maintain a fixed height, such as a collection of cards in a carousel.",
                    "title": "Truncate header or body text for cards that should maintain a fixed height, such as a collection of cards in a carousel.",
                    "src": "/static/1499dcb26a2c417a560915485c40242e/50383/card-carousel-do.png",
                    "srcSet": ["/static/1499dcb26a2c417a560915485c40242e/1efb2/card-carousel-do.png 370w", "/static/1499dcb26a2c417a560915485c40242e/50383/card-carousel-do.png 740w", "/static/1499dcb26a2c417a560915485c40242e/536c7/card-carousel-do.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Wrap text in Carousels. This will created a jagged baseline alignment." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/14bf5c3a3a11f2bd285b5968f2554425/536c7/card-carousel-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "43.24324324324324%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABoUlEQVR42o2RS2sTYRSG+4P8Ea67dedGEASx4A9wY+lCcGFBpChoBUVbq0iklyxawSbiorWkpSQ1zXSumWRm8nWSueUyk8vjVxfShTS+8HI4B96HczgzSE0mk4tCtVqVVjg8PuNXzaITD6VH0mPCpE+Ujgj7Q3oj0G0HXde5nL/QzOXB6alCsVRALy/jG3lCc52ulSOzc3jKIebXHG7hM/6PDcr5D+iGcTWwcqJQPnjHuPFCdik0XpN9v8aT5QX2tvKU7t3Ee3Ade/EO9ad30UzramC9WmN+5xOz6y95dFxgVf3JnlFkdkljZfUA+/4t3izeYGFtnsHzOc6mATUJfFXa5XbxPXPfVnh4tMMzZZ/HW7tsbteof/zCRn6Jtf1Nou23qNNPPsFzPdIwJnAFg6T7N5AO+oigg9tskUYJjmihadp0oON5iHOBJwR2symrBGTys70BoiVwnCbCP5cwdfqXK5UKjt3ANk0MVcVtNEjCkHGW0UsSoiAgaLfpxjG2Zf0f0LVtIhny5aa9KGKUpgzluVGnQ+C3/7gbJ6iK8k/gb+PslE6pmDfBAAAAAElFTkSuQmCC')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Wrap text when it will created a jagged bottom edge in a Carousel\"",
                    "title": "Wrap text when it will created a jagged bottom edge in a Carousel\"",
                    "src": "/static/14bf5c3a3a11f2bd285b5968f2554425/50383/card-carousel-dont.png",
                    "srcSet": ["/static/14bf5c3a3a11f2bd285b5968f2554425/1efb2/card-carousel-dont.png 370w", "/static/14bf5c3a3a11f2bd285b5968f2554425/50383/card-carousel-dont.png 740w", "/static/14bf5c3a3a11f2bd285b5968f2554425/536c7/card-carousel-dont.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h4 {...{
          "id": "vertical-stack"
        }}>{`Vertical Stack`}</h4>
        <p>{`When Cards are stacked on top of one another, prioritize Card content by letting text wrap instead
of truncating.`}</p>
        <p>{`In general, try to keep Card text under 3 lines of copy to avoid overly lengthy Cards.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Keep Cards descriptive and concise, ideally under 3 lines of text." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/653c6fa7848103542f33f05e506ee483/536c7/card-example-vstack-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAxklEQVR42pVRiwqDMBDz/z/SByr1/QCV1qqZuaHb3ITuIFxaSq7JebjUsiwwZoa1dscifdu2N+Dkv8q7XgzDAN8PoJRCGIZIkkREOYg4uLOg1hpVVQnSNEWe5yiKQjrvyrLch464qy/BaZoQBIEgiiLEcSycvz3OdOEsuK4r5nmW/GiNXGsjndk+uf3HshFbSmVomuZjEa+hm8BJcBwnyY72uJiua1HXNfq+R9u2e46l5OxsmY8pxF9yGRTPsuzkXI7llm8EH68OcJRyMf3LAAAAAElFTkSuQmCC')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "A Card with two lines of text as an example of what to do.",
                    "title": "A Card with two lines of text as an example of what to do.",
                    "src": "/static/653c6fa7848103542f33f05e506ee483/50383/card-example-vstack-do.png",
                    "srcSet": ["/static/653c6fa7848103542f33f05e506ee483/1efb2/card-example-vstack-do.png 370w", "/static/653c6fa7848103542f33f05e506ee483/50383/card-example-vstack-do.png 740w", "/static/653c6fa7848103542f33f05e506ee483/536c7/card-example-vstack-do.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Avoid using over 3 lines of text in a Card." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/7bfc0cc8f5839b824b820869eb2f2057/536c7/card-example-vstack-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAz0lEQVR42pWSiQqDMBBE/f9fFA/EIyreJtEYnbpbFGor2IWQySKTtxMdXEophSRJUFUV8jxHWZbYtu3jGzpfe0c514aUCnEcQwiBMAxZ13XNFzRNw3ocJe7qy3CaJhRFgSzLkKYpGxMxGVOPiI0xuAH8RSjhui7TRVHEu+d5rIMgYPNlWZ4bav0mJDIhcta0KE/qtW37f4YHoe/7vBMZjUo5dl13PswjQ2st50g5zbPZiTVTr+t6GpF+bGj2fIZhRN/3+y+kz/HI5FjW3o/8AvB4buqgjMBPAAAAAElFTkSuQmCC')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "A Card with five lines of text as an example of what not to do.",
                    "title": "A Card with five lines of text as an example of what not to do.",
                    "src": "/static/7bfc0cc8f5839b824b820869eb2f2057/50383/card-example-vstack-dont.png",
                    "srcSet": ["/static/7bfc0cc8f5839b824b820869eb2f2057/1efb2/card-example-vstack-dont.png 370w", "/static/7bfc0cc8f5839b824b820869eb2f2057/50383/card-example-vstack-dont.png 740w", "/static/7bfc0cc8f5839b824b820869eb2f2057/536c7/card-example-vstack-dont.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "overflow-actions"
        }}>{`Overflow Actions`}</h3>
        <p>{`Overflow menus contain related actions. They are typically placed in the upper-right or lower-right
corner of a card.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/536c7/card-example-overflow.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.83783783783784%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAe0lEQVR42qWPSw6AIBBDuf8ZYc8OFUfCtzommCjGiDZp2DBtn8CNSim7v0g0YS+OngrF3WetNZRSCCE0RbQ4DNPUsXALdM6BiJBSQs755BjjXvR6IYsPrLXw3h941XEr4dCuhRwmpYQxpkGeacEwdiIzan2vyNVdyH+0AgcjemkiCoPtAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Two cards vertically stacked. The first has an Overflow Menu placed at the end of the Header. The second has an Overflow Menu placed at the end of the Footer.",
                "title": "Two cards vertically stacked. The first has an Overflow Menu placed at the end of the Header. The second has an Overflow Menu placed at the end of the Footer.",
                "src": "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/50383/card-example-overflow.png",
                "srcSet": ["/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/1efb2/card-example-overflow.png 370w", "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/50383/card-example-overflow.png 740w", "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/536c7/card-example-overflow.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "methods"
        }}>{`Methods`}</h3>
        <p><strong parentName="p">{`Class name:`}</strong>{` `}<inlineCode parentName="p">{`CardComponent`}</inlineCode>{` `}<strong parentName="p">{`Module name:`}</strong>{` `}<inlineCode parentName="p">{`UIComponentsPlugin`}</inlineCode></p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`    public init(
        featureData: FeatureMetricsData,
        media: Media? = EmptyView(),
        header: Header? = EmptyView(),
        content: Content? = EmptyView(),
        footer: Footer? = EmptyView(),
        style: CardStyle = .elevated,
        size: CardSize = .medium,
        cardTapActionInfo: CardTapActionInfo? = nil,
        accessibilityLabel: String = "",
        customActions: [CardCustomAction] = [],
        overflowButtonConfig: CardOverflowButtonConfig? = nil,
        fillVerticalSpace: Bool = false
    )
`}</code></pre>
        <h3 {...{
          "id": "parameters"
        }}>{`Parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default values`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`featureData`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`FeatureMetricsData`}</inlineCode>{` that represents the feature name and the screen id where the card component is used.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`media`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A `}<inlineCode parentName="td">{`View`}</inlineCode>{` to be added to the card media slot.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`EmptyView()`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`header`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A `}<inlineCode parentName="td">{`View`}</inlineCode>{` to be added to the card header slot.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`EmptyView()`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`content`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A `}<inlineCode parentName="td">{`View`}</inlineCode>{` to be added to the card content slot.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`EmptyView()`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`footer`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A `}<inlineCode parentName="td">{`View`}</inlineCode>{` to be added to the card footer slot.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`EmptyView()`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`style`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A `}<inlineCode parentName="td">{`CardStyle`}</inlineCode>{` representing the style for the card.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`elevated`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A `}<inlineCode parentName="td">{`CardSize`}</inlineCode>{` representing the size for the card.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`medium`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`cardTapActionInfo`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional `}<inlineCode parentName="td">{`CardTapActionInfo`}</inlineCode>{` which contains the callback method for when the card is tapped and the A11Y label, defaults to nil.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`nil`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`accessibilityLabel`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`accessibilityLabel for the card. Defaults to an empty string. This will be used in conjunction with the `}<inlineCode parentName="td">{`customActions`}</inlineCode>{`. If the `}<inlineCode parentName="td">{`customActions`}</inlineCode>{` are provided, the entire card will be just one single focus stop in the voice over mode and the `}<inlineCode parentName="td">{`accessibilityLabel`}</inlineCode>{` will describe the contents of the card along with the `}<inlineCode parentName="td">{`customActions`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`""`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`customActions`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`List of `}<inlineCode parentName="td">{`CardCustomAction`}</inlineCode>{`. Defaults to an empty list. This will be used in conjunction with the `}<inlineCode parentName="td">{`accessibilityLabel`}</inlineCode>{`. If the `}<inlineCode parentName="td">{`customActions`}</inlineCode>{` are provided, the entire card will be just one single focus stop in the voice over mode and the `}<inlineCode parentName="td">{`accessibilityLabel`}</inlineCode>{` will describe the contents of the card along with the `}<inlineCode parentName="td">{`customActions`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`[]`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`overflowButtonConfig`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional `}<inlineCode parentName="td">{`OverflowButtonConfig`}</inlineCode>{` representing menu items and their positions within the card (header/footer).`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`nil`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`fillVerticalSpace`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`BOOL indicating if the card should take up all the available vertical space by adding the empty space below the content slot. Defaults to false.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <ul>
          <li parentName="ul">
            <p parentName="li">{`If the entire card is tappable and it has nested controls, use custom actions to ensure Full
Keyboard Access (FKA) users can access the nested controls as custom actions.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Do not use tappable cards when nested controls cannot be implemented as custom actions. For
example, ‘expandable containers’ do not work within custom actions so you cannot use custom
actions here. Given custom actions are the only way to ensure keyboard users can access nested
controls the only option is to not have tappable cards in the scenario.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`For blind users it is not always easy to determine where one card begins and where one ends or to
understand that the card is grouped. Sighted users can see the borders of the card and the
position of all the content within the card and understand that they form a card/group. In order
to make grouping clearer, implement one of the following in order of preference:`}</p>
            <ol parentName="li">
              <li parentName="ol">
                <p parentName="li">{`Have each card be a single accessibility element, because it is one element detected by
`}<a parentName="p" {...{
                    "href": "https://developer.apple.com/design/human-interface-guidelines/accessibility/#VoiceOver"
                  }}>{`VoiceOver`}</a>{`,
users understand it is grouped for a reason. This can work even if the card contains multiple
buttons because the additional buttons (including overflow) can be customActions as long as
they are all related.`}</p>
              </li>
              <li parentName="ol">
                <p parentName="li">{`Use a heading for the card title; this will successfully denote the start of the card content
and a new heading denotes the end of the previous card and start of a new one.`}</p>
              </li>
              <li parentName="ol">
                <p parentName="li">{`Use the card title in each button name as a suffix. Let's say the title name is 'Plants' e.g.
'First button, Plants' 'Second button, Plants' and 'Open Menu, Plants'.`}</p>
              </li>
            </ol>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Graphics and icons that do not communicate any additional information beyond what is represented
in text may be considered decorative and should not provide any text alternative.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`The button text and background color must meet the
`}<a parentName="p" {...{
                "href": "https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
              }}>{`minimum contrast requirement`}</a>{`.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`For more accessibility considerations, refer to the
`}<a parentName="p" {...{
                "href": "/guidelines/accessibility/overview"
              }}>{`Accessibility Guide`}</a>{`.`}</p>
          </li>
        </ul>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?type=design&node-id=10328-16305&mode=design&t=4YEmP5wB8niv6t6Z-4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/cab54f0a368f66b72c70fce9c98de1db/536c7/card-anatomy.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "59.189189189189186%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABEklEQVR42q1TAU7DMAzc/9/IxFampWWUUZo0cRL7cNoKKKq6IYh0ihzHJ58v2eGf124RiYxbzgyKaRPlzm3CeVFKsIOH82EVJUcxbhDK3GDTAdYjeoLTokGL11ByUbv8ruoH4XTI1Qvw3CF2FtYOU5crxCNhSvdLTsxakJEUgSJ8oAUKaVGB3kNOr4tOlx0+NEDdIV17HI8H7B/3cM7NU1lKk0EJ2x7y1G4QHi7TpV47MIRYR/BF3dRJjGi/ICxbpkxJOV9HQn5XKWdWkwRc626mnc2McpblDlNGyW/InVOpFiGRzjCgOlUwjdHnpHFUY8KAzPm2KUKpOAJWM4hUsr61Au89Qgifcckx/+Jh/+XrfQA3e7EeiXisnwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of the Card.",
                "title": "The basic elements of the Card.",
                "src": "/static/cab54f0a368f66b72c70fce9c98de1db/50383/card-anatomy.png",
                "srcSet": ["/static/cab54f0a368f66b72c70fce9c98de1db/1efb2/card-anatomy.png 370w", "/static/cab54f0a368f66b72c70fce9c98de1db/50383/card-anatomy.png 740w", "/static/cab54f0a368f66b72c70fce9c98de1db/536c7/card-anatomy.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol">
            <p parentName="li"><strong parentName="p">{`Card Container`}</strong>{`: Cards are empty containers with design tokens applied to them.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li"><strong parentName="p">{`Card Image (Optional)`}</strong>{`: Supporting image or media element`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li"><strong parentName="p">{`Card Header (Optional)`}</strong>{`: Commonly used for card titles`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li"><strong parentName="p">{`Card Body (Optional)`}</strong>{`: Main content area that can hold custom layouts and other components`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li"><strong parentName="p">{`Card Footer (Optional)`}</strong>{`: Mainly for actions or metadata`}</p>
          </li>
        </ol>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <p>{`Cards are empty containers with basic styling applied to them. They are highly composable and can
hold many types of content related to a single topic. Cards should summarize details about that
topic and generally be interactive, taking users to full details.`}</p>
        <h3 {...{
          "id": "cards-are-composable-1"
        }}>{`Cards are Composable`}</h3>
        <p>{`Cards are containers that hold related content and actions about a topic. They are highly composable
building blocks with any type of content, including other components, layouts, images, and steps.`}</p>
        <h3 {...{
          "id": "cards-are-actionable-1"
        }}>{`Cards are Actionable`}</h3>
        <p>{`Cards should be interactive and lead to more details about a topic.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Make the entire card a primary action with one large touch target." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/536c7/card-usage-actionable-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Card whose entire surface area is interactive.",
                    "title": "Card whose entire surface area is interactive.",
                    "src": "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/50383/card-usage-actionable-do.png",
                    "srcSet": ["/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/1efb2/card-usage-actionable-do.png 370w", "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/50383/card-usage-actionable-do.png 740w", "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/536c7/card-usage-actionable-do.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Avoid using Primary Buttons in cards." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/87a8fe784731c4c871601a0554ddf70d/536c7/card-usage-actionable-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Primary Button in a CardFooter",
                    "title": "Primary Button in a CardFooter",
                    "src": "/static/87a8fe784731c4c871601a0554ddf70d/50383/card-usage-actionable-dont.png",
                    "srcSet": ["/static/87a8fe784731c4c871601a0554ddf70d/1efb2/card-usage-actionable-dont.png 370w", "/static/87a8fe784731c4c871601a0554ddf70d/50383/card-usage-actionable-dont.png 740w", "/static/87a8fe784731c4c871601a0554ddf70d/536c7/card-usage-actionable-dont.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <p>{`A Card may have multiple tap targets in `}<inlineCode parentName="p">{`CardFooter`}</inlineCode>{`.`}</p>
        <p>{`Try to keep Cards focused and avoid crowding the Card with multiple actions`}</p>
        <h3 {...{
          "id": "cards-are-grouped-1"
        }}>{`Cards are Grouped`}</h3>
        <p>{`Cards are normally part of a larger collection of related cards. Isolated cards are rare. Make sure
Cards are modular - each card being independent of each other.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e70797cc838324765c7358edfc00f804/536c7/card-usage-group.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "45.94594594594595%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABsElEQVR42oXRS2sTYRTG8W7cuMlCUL+BqNn7NfoBFLqSrsWFQlSwtGKFJjsXxSKKWrtIrUUtCUrNQgtqGpPJZWY6MhMzMeOFycykEzqX/H1thBaqzYF3c87h93J4xthXQRAiywq2ZaFrLWzX3+0POFi9Xk/sysP5YG9jbP9SGASoqsKdQp77+QwY0wyMW6BPs6Xe5WZpmUW9BvI73NwCjdLHIRjH/wajOEL6XCWTGSe/coq1bJIPa2covz6LnDst+kmebUxBdgZvZhz5/ZvDwR0x0IubpOcmOLl0haP3UiQeXiOxOEXiQYr5J2mylWVYmaWXvoC8sT4KHNDcLHJp9iJHHqc4sXSdY09vcPzRVZILk5x7/orLhVVYvY2XPj8aDMKQrUaDSr3IC61M7ovES63CW6NKo1mmYP2iZn8n/qbR/rROrVz6G8r/QBGKoqq4dpef7Y5I+wdWs4XveMRRTBzsDBP2+9RUDalaPTzlP6CqqHS7XVqmidk2+Wq20A2DjtXB8TwRXIy/vY0sLpEkaTSoKAr9vo8tUFcAnniO4+C67i4UCzCMIvFZm3q9fgD8DTMch3JX0SupAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A Group of Cards",
                "title": "A Group of Cards",
                "src": "/static/e70797cc838324765c7358edfc00f804/50383/card-usage-group.png",
                "srcSet": ["/static/e70797cc838324765c7358edfc00f804/1efb2/card-usage-group.png 370w", "/static/e70797cc838324765c7358edfc00f804/50383/card-usage-group.png 740w", "/static/e70797cc838324765c7358edfc00f804/536c7/card-usage-group.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Cards should be modular, meaning each card can exist independently without relying on another card's
content.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Cards should be able to exist as separate modules." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/d5a111c6982ec7b3dce4dfc1774ef2c8/3cb0f/card-modular-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "50.54054054054055%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "A group of Cards related to design system topics.",
                    "title": "A group of Cards related to design system topics.",
                    "src": "/static/d5a111c6982ec7b3dce4dfc1774ef2c8/3cb0f/card-modular-do.png",
                    "srcSet": ["/static/d5a111c6982ec7b3dce4dfc1774ef2c8/1efb2/card-modular-do.png 370w", "/static/d5a111c6982ec7b3dce4dfc1774ef2c8/3cb0f/card-modular-do.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Avoid splitting content up between cards" mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/457a8e4eddc469b38a6f85dda2d20436/3cb0f/card-modular-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "51.08108108108108%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Two cards that break up content between themselves",
                    "title": "Two cards that break up content between themselves",
                    "src": "/static/457a8e4eddc469b38a6f85dda2d20436/3cb0f/card-modular-dont.png",
                    "srcSet": ["/static/457a8e4eddc469b38a6f85dda2d20436/1efb2/card-modular-dont.png 370w", "/static/457a8e4eddc469b38a6f85dda2d20436/3cb0f/card-modular-dont.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "cards-are-consumable-1"
        }}>{`Cards are Consumable`}</h3>
        <p>{`Cards should be easily identifiable when contrasted against the rest of the page, grouped by a
perceived boundary.`}</p>
        <p>{`Borders, negative space, depth, or background color to a Card are all acceptable ways to separate a
Card from the rest of the UI.`}</p>
        <h3 {...{
          "id": "cards-are-adaptive-1"
        }}>{`Cards are Adaptive`}</h3>
        <p>{`Card width and height should be adaptive to their context, alignment and sizing being determined by
their parent layout. Truncation rules should be set by feature teams based on the layout used.`}</p>
        <h4 {...{
          "id": "vstacks-1"
        }}>{`vStacks`}</h4>
        <p>{`Cards that are stacked on top of each other should prioritize card content instead of truncating
text. Cards by default span edge-to-edge for screen margins.`}</p>
        <p>{`As a rule of thumb, keep copy 3 lines or less avoid to overwhelming users.`}</p>
        <h4 {...{
          "id": "carousel-layout-1"
        }}>{`Carousel Layout`}</h4>
        <p>{`Cards in a carousel should truncate text content instead of growing the content to preserve the
baseline alignment of all cards in the same group.`}</p>
        <h3 {...{
          "id": "when-to-use-2"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`To group and present related information (icons, text, actions) in a digestible way.`}</li>
          <li parentName="ul">{`To provide an entry point to more details. Cards provide a summary rather than the full details
right away.`}</li>
          <li parentName="ul">{`To contain flexible layouts. Layouts may contain any type of content. Cards typically have similar
widths, but heights should accommodate varying content.`}</li>
          <li parentName="ul">{`To display variable content that is different between Cards. Cards are differentiated from one
another by their content.`}</li>
          <li parentName="ul">{`To build other card-like components, like an
`}<a parentName="li" {...{
              "href": "/components/popups/alert-dialog#tab=android"
            }}>{`Alert Dialog`}</a>{`.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-2"
        }}>{`When to Use Something Else`}</h3>
        <ol>
          <li parentName="ol">{`Use `}<a parentName="li" {...{
              "href": "/components/text/text"
            }}>{`Text`}</a>{` if content cannot exist separately from other Cards. Don’t
force users to read card to card.`}</li>
          <li parentName="ol">{`Use a `}<a parentName="li" {...{
              "href": "/components/containers/list#tab=android"
            }}>{`List`}</a>{` when users need to compare and contrast
options against each other.`}</li>
          <li parentName="ol">{`Use a `}<a parentName="li" {...{
              "href": "/components/buttons/button#tab=android"
            }}>{`Button`}</a>{` for simple call to actions.`}</li>
          <li parentName="ol">{`Use a `}<a parentName="li" {...{
              "href": "/components/containers/table"
            }}>{`Table`}</a>{` for tabular content with multiple columns.`}</li>
          <li parentName="ol">{`If no action is provided on the card, consider if a Card needs to be used at all.`}</li>
        </ol>
        <h2 id="android-examples">Examples</h2>
        <h3 {...{
          "id": "composition-1"
        }}>{`Composition`}</h3>
        <p>{`Cards are composed of four content slots that are used for different purposes. All content slots are
optional.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/27ce798bf0291c916c142abec4d7be7c/536c7/card-examples-composition.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "39.729729729729726%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA3ElEQVR42pWSv0sDQRCF83+rfQoTBRsrC20jKgZBTtNorQYkpEoT84Mk5Hb3duYuFn7urVikSGCLB495Mx8DMw1jHbvkbIF3fkt1bd9MY1dgXcGicIztmi+bB5no61qdJQFzY1GvdKbvHA67tEc9WqNnDob33Mz6lCFbh540oHiuPzccPSmt1w3HL1X0t4NvypAlA0WE7E04uzNcZZbLzETf+1BUfexJAjqt4LELp024OP/TSZOf7AFXVmnA/6PIYoWMJ+h0jk5m0ctyhS3CUVKBcdMAzb1sy+1/m1/P0FYPSpbREgAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Card with content slots labelled as Image, Header, Body, Footer",
                "title": "Card with content slots labelled as Image, Header, Body, Footer",
                "src": "/static/27ce798bf0291c916c142abec4d7be7c/50383/card-examples-composition.png",
                "srcSet": ["/static/27ce798bf0291c916c142abec4d7be7c/1efb2/card-examples-composition.png 370w", "/static/27ce798bf0291c916c142abec4d7be7c/50383/card-examples-composition.png 740w", "/static/27ce798bf0291c916c142abec4d7be7c/536c7/card-examples-composition.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "cardcontainer-1"
        }}>{`CardContainer`}</h4>
        <p>{`Card containers hold all card elements. Their size is determined by their content.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3a175341834d0b52aa0f0211e41ec62a/536c7/card-examples-container.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.83783783783784%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAALUlEQVR42mP4T2XAQHMD//37R5RGXOrIMhCkZmAN/Pv3L0FMtIHILsCH6RbLAKmNfTAZ1LEUAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Card Container without content (empty white box with a depth token applied)",
                "title": "Card Container without content (empty white box with a depth token applied)",
                "src": "/static/3a175341834d0b52aa0f0211e41ec62a/50383/card-examples-container.png",
                "srcSet": ["/static/3a175341834d0b52aa0f0211e41ec62a/1efb2/card-examples-container.png 370w", "/static/3a175341834d0b52aa0f0211e41ec62a/50383/card-examples-container.png 740w", "/static/3a175341834d0b52aa0f0211e41ec62a/536c7/card-examples-container.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "cardimage-1"
        }}>{`CardImage`}</h4>
        <p>{`By default, images render at the top of the card with an aspect ratio of `}<inlineCode parentName="p">{`{16/9}`}</inlineCode>{`.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ec6ad7d517437257d056548da3c0d948/536c7/card-media.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.83783783783784%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABPElEQVR42o2R6U4CQRCEef/n8aeJ0QQVvJCAIAgLe7AXyy57X8PnLEJCjAE7qZmkMl1dNd3ipMRuRy1OISjrmqKsqWvxAyEOvEDIN7+r1RxHOkwrPlcxcydFcVNUL0Pb5Bh+ibltUKH7BQs3wwnyM4IH3k9KnpWQvpYwNBLGVsaXKwU2tUTFfF2ieJXkmyG57LsgGEiHPTVmuEr3YlMnZ9EIGBH9ZcDL3GMuBzSitnRcydhnI0dZxcyKWa4z6aDYN/fVkKepy6vi05H3m7JhJp064T8Ek7xG83IpmLK0Ah5HBlcPI256Cp2JRXdiMtC2e+frqJJ/KC5EjgvaI4vrd53uWOdlanM3MLgfrbjtq7Q/dBm3ZOJkmOeWcqysqFCdEMNLsP0Y04tQLR/NDlAldDfEi0rsICVIij+X8g1yP2YadN4aEgAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Card with an image set to the Aspect Ratio 16/9",
                "title": "Card with an image set to the Aspect Ratio 16/9",
                "src": "/static/ec6ad7d517437257d056548da3c0d948/50383/card-media.png",
                "srcSet": ["/static/ec6ad7d517437257d056548da3c0d948/1efb2/card-media.png 370w", "/static/ec6ad7d517437257d056548da3c0d948/50383/card-media.png 740w", "/static/ec6ad7d517437257d056548da3c0d948/536c7/card-media.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><inlineCode parentName="p">{`CardImage`}</inlineCode>{` support custom aspect ratios.`}</p>
        <h4 {...{
          "id": "cardheader-1"
        }}>{`CardHeader`}</h4>
        <p><inlineCode parentName="p">{`CardHeader`}</inlineCode>{` is an optional sub-component. It mainly serves as a title to identify the Card and
defaults to `}<inlineCode parentName="p">{`h3`}</inlineCode>{`.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/23b78b5c9b43de2be588042c63d033df/536c7/card-anatomy-header.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAiUlEQVR42q2RQQrCMBREe/8juO0ZPIlYVFCJVMSmNptiTX4y4ze6qLtaHHiLP4vHwC/45xRfV0yE60lJrPdbuu7Gzjnaa5M7mJa8++lCDJ5YnykqXZYL7qoVzdHQNjaLcFLhEH5YOEp4RMbeUw4tkbR4AUU+TBZihErT5kJ4tYkWAW9ZnLFw7lOeAqnZDJbwd9MAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of CardHeader include a Title, Subtext or Body Text, and a leading Icon or Avatar.",
                "title": "The basic elements of CardHeader include a Title, Subtext or Body Text, and a leading Icon or Avatar.",
                "src": "/static/23b78b5c9b43de2be588042c63d033df/50383/card-anatomy-header.png",
                "srcSet": ["/static/23b78b5c9b43de2be588042c63d033df/1efb2/card-anatomy-header.png 370w", "/static/23b78b5c9b43de2be588042c63d033df/50383/card-anatomy-header.png 740w", "/static/23b78b5c9b43de2be588042c63d033df/536c7/card-anatomy-header.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Title`}</strong>{`: often communicates the subject of the card, such as the name of a feature area.`}</li>
          <li parentName="ol"><strong parentName="li">{`Subtext or Body Text(Optional)`}</strong>{`: Subtext are smaller text elements, such as a byline in an
article card. Body text includes body content, such as a snippet of an article.`}</li>
          <li parentName="ol"><strong parentName="li">{`Lead Area (Optional)`}</strong>{`: An `}<inlineCode parentName="li">{`AccentIcon`}</inlineCode>{` or `}<inlineCode parentName="li">{`Avatar`}</inlineCode>{` may be used at the start of `}<inlineCode parentName="li">{`CardHeader`}</inlineCode>{`.`}</li>
        </ol>
        <p><inlineCode parentName="p">{`CardHeader`}</inlineCode>{` may be swapped with a custom header.`}</p>
        <h4 {...{
          "id": "cardbody-1"
        }}>{`CardBody`}</h4>
        <p><inlineCode parentName="p">{`CardBody`}</inlineCode>{` serves as the primary content slot of the Card. This is where the main content should go.
Any type of content may be used in `}<inlineCode parentName="p">{`Card`}</inlineCode>{`.`}</p>
        <p>{`In general, try to limit the amount of content that goes into a Card, as it’s meant to be a preview.
One example could be to limit body text to 3 lines before truncating content.`}</p>
        <h4 {...{
          "id": "cardfooter-1"
        }}>{`CardFooter`}</h4>
        <p><inlineCode parentName="p">{`CardFooter`}</inlineCode>{` should be used for additional actions or metadata.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e9207607494314246ec0aa1a6fda0699/07d7d/card-examples-card-footer.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "38.91891891891891%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAdUlEQVR42r2RywqAIBBF+/+/lB5bm0DzQfc2WrkMF9HAwZE7cxAc+HEN/wh3RZSNhIBYUfuK3HmvELwEGJVZWVS28OoLE3jYo871CQHGGBlCYM6Z1loaY+icY0qpZWWuU8i2VE7vPUWk3R+6hc8r3+ot//yXTwqAelTwOAbbAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "CardFooter using a single secondary button and overflow menu",
                "title": "CardFooter using a single secondary button and overflow menu",
                "src": "/static/e9207607494314246ec0aa1a6fda0699/50383/card-examples-card-footer.png",
                "srcSet": ["/static/e9207607494314246ec0aa1a6fda0699/1efb2/card-examples-card-footer.png 370w", "/static/e9207607494314246ec0aa1a6fda0699/50383/card-examples-card-footer.png 740w", "/static/e9207607494314246ec0aa1a6fda0699/07d7d/card-examples-card-footer.png 1478w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Don’t use Primary Buttons in `}<inlineCode parentName="p">{`CardFooter`}</inlineCode>{`. Instead, the primary action should be placed on the
entire Card.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Place primary actions on the entire surface area of the card." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/536c7/card-usage-actionable-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "An interactive card.",
                    "title": "An interactive card.",
                    "src": "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/50383/card-usage-actionable-do.png",
                    "srcSet": ["/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/1efb2/card-usage-actionable-do.png 370w", "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/50383/card-usage-actionable-do.png 740w", "/static/1ff2be702df4fdcbbfd3c0cae1edc3cc/536c7/card-usage-actionable-do.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="A Primary Button placed in the Footer of a Card" mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/87a8fe784731c4c871601a0554ddf70d/536c7/card-usage-actionable-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "A Primary Button placed within the footer of the Card",
                    "title": "A Primary Button placed within the footer of the Card",
                    "src": "/static/87a8fe784731c4c871601a0554ddf70d/50383/card-usage-actionable-dont.png",
                    "srcSet": ["/static/87a8fe784731c4c871601a0554ddf70d/1efb2/card-usage-actionable-dont.png 370w", "/static/87a8fe784731c4c871601a0554ddf70d/50383/card-usage-actionable-dont.png 740w", "/static/87a8fe784731c4c871601a0554ddf70d/536c7/card-usage-actionable-dont.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <p>{`Only display one Secondary or Tertiary Button in `}<inlineCode parentName="p">{`CardFooter`}</inlineCode>{`. Any additional actions should be
placed in the Overflow `}<inlineCode parentName="p">{`Menu`}</inlineCode>{`.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/536c7/card-example-overflow.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.83783783783784%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAe0lEQVR42qWPSw6AIBBDuf8ZYc8OFUfCtzommCjGiDZp2DBtn8CNSim7v0g0YS+OngrF3WetNZRSCCE0RbQ4DNPUsXALdM6BiJBSQs755BjjXvR6IYsPrLXw3h941XEr4dCuhRwmpYQxpkGeacEwdiIzan2vyNVdyH+0AgcjemkiCoPtAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "CardFooter with one Secondary Button and an Overflow Menu.",
                "title": "CardFooter with one Secondary Button and an Overflow Menu.",
                "src": "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/50383/card-example-overflow.png",
                "srcSet": ["/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/1efb2/card-example-overflow.png 370w", "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/50383/card-example-overflow.png 740w", "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/536c7/card-example-overflow.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><inlineCode parentName="p">{`CardFooter`}</inlineCode>{` can be swapped with a custom footer component, if you'd like.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/288abfec9d9c3c22db60ad80c4c5fbc0/536c7/card-footer-custom.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.83783783783784%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA5ElEQVR42q1Sy66CMBD1/7f6Q6z07nXj4waCDx5SQQK0tJ3jFAFNNC6Mk0w67Zw5Z6btBD+2CRF1Qdu28DwPQRDAnSmlYK39nrCqa0xnM8wXf6gbiUzkLKK7nMPc3bKIgXkSGupHwiFwIJeUUqK4lrgURdf1YEmSYb0N4Psh4lMEWBbQXGPse0IpFfbHGMco4e4yxtND3RiIXYr/ZQyxTdGGZ+iDgA4Zd236VgfCvsjwiNJfQR02aCKB6iRgsnLEkhuT7h0RC5Du/WXkfk9lDYgzkF9g8goyzbt1vKsn//gov/42N9JSc2sv/CHOAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A custom CardFooter used in place of the default CardFooter.)",
                "title": "A custom CardFooter used in place of the default CardFooter.)",
                "src": "/static/288abfec9d9c3c22db60ad80c4c5fbc0/50383/card-footer-custom.png",
                "srcSet": ["/static/288abfec9d9c3c22db60ad80c4c5fbc0/1efb2/card-footer-custom.png 370w", "/static/288abfec9d9c3c22db60ad80c4c5fbc0/50383/card-footer-custom.png 740w", "/static/288abfec9d9c3c22db60ad80c4c5fbc0/536c7/card-footer-custom.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "overflow-menu-1"
        }}>{`Overflow Menu`}</h3>
        <p>{`Overflow `}<a parentName="p" {...{
            "href": "../popups/menu#android"
          }}>{`Menus`}</a>{` contain related actions on the Card. They are typically
placed in `}<inlineCode parentName="p">{`CardHeader`}</inlineCode>{` or `}<inlineCode parentName="p">{`CardFooter`}</inlineCode>{`.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/9e6f49d1a4a8af1f93bf8074f00331d9/536c7/card-usage-menu.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.83783783783784%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAApklEQVR42q2RSw7DIAxEc/9rRmqlLgghfBLbMMGoZVGpTaJ20Cyw0WMwA54qdckiSPeE7bGhTAWw6C6m9p20c980dGApiClivI0w1oCYel0Vas/MFkfqQL2YiRFDxJpWSBYI18QpwS++gbWf88mE71KAiMB7jxBCq+n+lfgyUJVzbjAi+g9QAWoFUn2u+ueEzjlYO2GuH6LzPP8pH+bIzM2aUtMeAXe1L3marzyI/gAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "An Overflow Menu in the header, expanded, with 3 actions.",
                "title": "An Overflow Menu in the header, expanded, with 3 actions.",
                "src": "/static/9e6f49d1a4a8af1f93bf8074f00331d9/50383/card-usage-menu.png",
                "srcSet": ["/static/9e6f49d1a4a8af1f93bf8074f00331d9/1efb2/card-usage-menu.png 370w", "/static/9e6f49d1a4a8af1f93bf8074f00331d9/50383/card-usage-menu.png 740w", "/static/9e6f49d1a4a8af1f93bf8074f00331d9/536c7/card-usage-menu.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "link-1"
        }}>{`Link`}</h3>
        <p>{`Cards should be interactive. They support the `}<inlineCode parentName="p">{`Link`}</inlineCode>{` and `}<inlineCode parentName="p">{`onTapGesture`}</inlineCode>{` properties. Both properties
can be passed to `}<inlineCode parentName="p">{`Card`}</inlineCode>{` so that the correct tag is created for screen readers.`}</p>
        <h4 {...{
          "id": "layouts--long-text-1"
        }}>{`Layouts & Long Text`}</h4>
        <p>{`Cards should be placed in a layout to control alignment & size. Truncation rules and line limits are
configurable based on the layout.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion type="do" guidance="Truncate Card text to keep all Cards in the group at the same height." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/86e1b4b4dde0f1e801eea75730451263/536c7/card-layouts-carousel-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB4UlEQVR42l2RS28SYRiF+2+Mf8MYF8aFunDjQo02JrrQYNLG28I0uqpxYbqwG2SDl6hRAzEBgyZKb9g0iFgqaYGZYYCBYS7AwFwfB1pt9U3O7pzne8/7TTEZH6wy9XIapzxPtKhyPZEn8qlCZLnNta9Npr9oxEsqCwWV6bTIXGaLpc0KP3WHouGi2f6ENLXL87C7P6gUXjNcO8VMVuTQrbdEXuW5mVM5nSgy83yR2XWTo48/cOz8GW4/jfMumSKzniezoyCY9j4wcB2aQo3C9yzmyrkwqHL4wh1O3I9xb6PPwtIyD57dZXZV52T8G0+uHuHliyiJDYGPK3mSmc/Uur0DG3oO8naZQmmVoHSD+eKAS7EUVxIFLuYGnE13OP6mzaOiycNkltTcZWLRRd7/6rEmWuSENi3LOwAcb+n71BtNbNuia5hURQlRqlMRRORWi6HjYrvhrcw+QuhTVI2mohIEwZ74FzgeudnGCYOGYbBV2qRW3UGWpVB1LGuA6/k49gjPtbGGIxqtDv/P1AS9J7mhYI9sBoMhjfD1jtbDD/bNnuPQ7YfnMQOq7SFCXRl3+5vfBYZVCavgeUhSA1PTMc0QqLtsKyP0Xvh7njvx2JaFrhloeh+lrVGXmpPcn/wY+BtcnTwr/ownzgAAAABJRU5ErkJggg==')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Cards in a carousel should truncate text content instead of growing content to preserve the baseline alignment of all cards in the same group.",
                    "title": "Cards in a carousel should truncate text content instead of growing content to preserve the baseline alignment of all cards in the same group.",
                    "src": "/static/86e1b4b4dde0f1e801eea75730451263/50383/card-layouts-carousel-do.png",
                    "srcSet": ["/static/86e1b4b4dde0f1e801eea75730451263/1efb2/card-layouts-carousel-do.png 370w", "/static/86e1b4b4dde0f1e801eea75730451263/50383/card-layouts-carousel-do.png 740w", "/static/86e1b4b4dde0f1e801eea75730451263/536c7/card-layouts-carousel-do.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="A group of Cards in a carousel whose text does not truncate, creating a jagged baseline in the row." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/d37fd9ee4eb81cf9cc97321f9d4d49a2/536c7/card-layouts-carousel-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB4ElEQVR42m2QS2sTYRSG+2sE9+Je3Chida8bEUELCiIIiuJGQUiJC3fxAgqtRSspViwuWjWmUdNqYmvbpE3aZCaTyWWSzGQuSeaSp19awaC+cDh8cM7zvecdYaB+H7ollO0EneIEb/M64fgmoWSJ8ZTGg7TG3VSb9/kG03mD+ymdyJLEj22Foh2wY3qYXrCHGtkH+nitFMXVKXrJUa7FZQ7cnuXi6w3GvmgciWY5Oxnl+rLOscgHDl+4xOXIFNF388z/zLAgG0iW+wfY913qiszaShz96zlufdc4eP4eZ0IThFctHn9b5MnLK9xcMjjxNE547CivZl4wl8yykFhm7mOMYtMcciiAcnaDlfVF+CWAaZPjoUlGn33mVMzg5KzMoecF7qR1bswkeHP1NI8ejjOdsYjlDD5lJJRhh4MMfVdAFQXXrqKI39YKCllZZV3UltqkJrJqdT1UrclmbosdpYJUbdDzA3pBgN9nCPhb5UqdngA7tkVFkdAqZarlElW1jO+5uJ7I2u0R+B6dTpdKTeNv/QN0xYLR8SjpHjnNo+X44oKAgQHfE2/bR7Wg0Ogiqf8DCruIwUEpJRVTNzDbJrph02jZWKaN6zh0bQe73aatmxiGQ62u783j+/v7gw7sAmFVPB/UOWPkAAAAAElFTkSuQmCC')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Cards in a carousel whose text does not truncate, creating unequal height Cards in the same row.",
                    "title": "Cards in a carousel whose text does not truncate, creating unequal height Cards in the same row.",
                    "src": "/static/d37fd9ee4eb81cf9cc97321f9d4d49a2/50383/card-layouts-carousel-dont.png",
                    "srcSet": ["/static/d37fd9ee4eb81cf9cc97321f9d4d49a2/1efb2/card-layouts-carousel-dont.png 370w", "/static/d37fd9ee4eb81cf9cc97321f9d4d49a2/50383/card-layouts-carousel-dont.png 740w", "/static/d37fd9ee4eb81cf9cc97321f9d4d49a2/536c7/card-layouts-carousel-dont.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "size-1"
        }}>{`Size`}</h3>
        <p>{`Three size options are provided by default - `}<inlineCode parentName="p">{`small,`}</inlineCode>{` `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, and `}<inlineCode parentName="p">{`large`}</inlineCode>{`. Increases in size are
represented as increases in Card padding.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8158799aa02c091ae9772fdd8f01eee2/536c7/card-sizes.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "71.35135135135134%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAABLklEQVR42q1Sy07DMBDs/38Qd9QTCMpD5UQAtSTQKs2zieP3sOuGcALFopZGHs9mN+vxLnDmtfgt0AqL8mjRDRaPmcZ9qgJnjWOzCvpxP3QWy5dh0ru+J4jpfJkMqISb36G2HpuDQtUZHLnDdMADgXlJ2rZQMNbHXfnUsg/gZP7J9znaQ07JyavP2qKg669SjdutCvyDNI75mII1+XOx7uGchzJUvGwIbeCscayO8dBQUrKTyAqNvDa4fhO4ehXIGxM0jvE38R6Oa9A+4F9zWJJf+8ai7i3u3hW9sg6ctaqzcQWFdnjKFCTt0ni0vUJDYD6Qts4k+ennD3bROyyTn8HWSgZMg/1MMyld3JV3tUYaHkXjZiOxorHJR21Pe7SHpxn2oW+hXLCBOWt/Pc8XJThM2TZFYd0AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Small, Medium, and Large Cards placed side by side for comparison.",
                "title": "Small, Medium, and Large Cards placed side by side for comparison.",
                "src": "/static/8158799aa02c091ae9772fdd8f01eee2/50383/card-sizes.png",
                "srcSet": ["/static/8158799aa02c091ae9772fdd8f01eee2/1efb2/card-sizes.png 370w", "/static/8158799aa02c091ae9772fdd8f01eee2/50383/card-sizes.png 740w", "/static/8158799aa02c091ae9772fdd8f01eee2/536c7/card-sizes.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}><strong parentName="th">{`Size`}</strong></th>
              <th parentName="tr" {...{
                "align": null
              }}><strong parentName="th">{`Token`}</strong></th>
              <th parentName="tr" {...{
                "align": null
              }}><strong parentName="th">{`Usage`}</strong></th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`small`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Space.x3`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Cards using a horizontal layout`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`medium`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Space.x4`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Default cards, typically in a group`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`large`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`Space.x5`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Large, standalone cards like a `}<inlineCode parentName="td">{`DialogCard`}</inlineCode></td>
            </tr>
          </tbody>
        </table>
        <h3 {...{
          "id": "custom-padding--spacing-1"
        }}>{`Custom Padding & Spacing`}</h3>
        <p>{`You may choose to overriding defaults with `}<inlineCode parentName="p">{`space`}</inlineCode>{` tokens. See the `}<a parentName="p" {...{
            "href": "/styles/tokens/space"
          }}>{`Space`}</a>{` article to
learn more.`}</p>
        <h3 {...{
          "id": "style-1"
        }}>{`Style`}</h3>
        <p>{`Three visual styles of Cards - `}<inlineCode parentName="p">{`Elevated`}</inlineCode>{`, `}<inlineCode parentName="p">{`Filled`}</inlineCode>{`,and `}<inlineCode parentName="p">{`Outlined`}</inlineCode>{`. There is no difference between
Card styles and their function.`}</p>
        <p>{`To determine what style to use, consider contrast requirements, and the consistent application of
style across a feature.`}</p>
        <h4 {...{
          "id": "elevated-1"
        }}>{`Elevated`}</h4>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/99eefb85069827f5d2004144757eb41b/536c7/card-style-elevated.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAJUlEQVR42mP4T2XAgEvi379/eDFJBuLTQEgN/QwkBpMUhuRGCgC4493uFbAUHwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Elevated Card with a depth (shadow) applied.",
                "title": "Elevated Card with a depth (shadow) applied.",
                "src": "/static/99eefb85069827f5d2004144757eb41b/50383/card-style-elevated.png",
                "srcSet": ["/static/99eefb85069827f5d2004144757eb41b/1efb2/card-style-elevated.png 370w", "/static/99eefb85069827f5d2004144757eb41b/50383/card-style-elevated.png 740w", "/static/99eefb85069827f5d2004144757eb41b/536c7/card-style-elevated.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Use `}<inlineCode parentName="p">{`depth`}</inlineCode>{` to show or hide an elevation on the card.`}</p>
        <p>{`When depth is set to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, the `}<inlineCode parentName="p">{`border`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`false`}</inlineCode>{`. Don’t use both depth and border on a
card.`}</p>
        <h4 {...{
          "id": "filled-1"
        }}>{`Filled`}</h4>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/457e9c3e54f13105803452b90e13c624/536c7/card-style-filled.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAQElEQVR42mP48PHTf2z4/YePeDEufQyYBn36//nL1/8/f/7Ci0FqiDTw4/9v33/8JwS+ff+O1aX0MJDKXqY0UgBcvs7aErEZEQAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "A white filled card with no depth or border applied, against a light grey background",
                "title": "A white filled card with no depth or border applied, against a light grey background",
                "src": "/static/457e9c3e54f13105803452b90e13c624/50383/card-style-filled.png",
                "srcSet": ["/static/457e9c3e54f13105803452b90e13c624/1efb2/card-style-filled.png 370w", "/static/457e9c3e54f13105803452b90e13c624/50383/card-style-filled.png 740w", "/static/457e9c3e54f13105803452b90e13c624/536c7/card-style-filled.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Filled Cards have default their background color to `}<inlineCode parentName="p">{`frenchVanilla100`}</inlineCode>{`. Filled cards do not have a
`}<inlineCode parentName="p">{`border`}</inlineCode>{` or `}<inlineCode parentName="p">{`depth`}</inlineCode>{` applied by default.`}</p>
        <p>{`The `}<inlineCode parentName="p">{`color`}</inlineCode>{` parameter can be used to change the background color of the card to something else.`}</p>
        <p>{`In general, avoid changing the background color of Cards unless it's necessary for contrast or
feature requirements.`}</p>
        <h4 {...{
          "id": "outlined-1"
        }}>{`Outlined`}</h4>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ac5903fe2d84d34fb1834679d1c4d242/536c7/card-outline.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "32.43243243243243%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAIUlEQVR42mP4T2XAgE3w379/RGGSDCQEBt5AqnqZkkgBALBV3ejd7BHdAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Outlined Card with a border applied to its container",
                "title": "Outlined Card with a border applied to its container",
                "src": "/static/ac5903fe2d84d34fb1834679d1c4d242/50383/card-outline.png",
                "srcSet": ["/static/ac5903fe2d84d34fb1834679d1c4d242/1efb2/card-outline.png 370w", "/static/ac5903fe2d84d34fb1834679d1c4d242/50383/card-outline.png 740w", "/static/ac5903fe2d84d34fb1834679d1c4d242/536c7/card-outline.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`A border may be used instead of depth. Outlined cards should not have a depth but may have a
background color.`}</p>
        <h3 {...{
          "id": "images-1"
        }}>{`Images`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0d73c20653948a54355af4a43a4147fc/536c7/card-examples-media.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "43.24324324324324%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABa0lEQVR42q2RO0tCARTH/QANDbVVEA19gIyoKade5N4QEZQ2FFFR0YsuRaIhJtZQloQkaQ8oCoxSkjTEkiwJaooeKF6om+bzSt77z65DDzQd+sOPc4Zzfhw4PPwKy7KI0DRCHHGE4inCyT4aoxHjiHFz6cL7LvqM94WCYJyAYHoGTQoFhCoV6pNUSWZRPkqgpKMH/PYueEjyx15GIflKQTQnw7BaCYleDYlhGWLJJFqlUxAq5SgeGESBqBt3vpSQySYMBAPQbmqwY1yH1WHEknYelTXV6B3pg/5oG4RmAWKlAl6Kyu3CcICCSSfFyZ4Kt64t6NbkyMsvxATRD/fVAYz7q9g1qBEM+LMJGa6+kU/QtvGx0VkBG1GLQ1kzikrLsDjUgJuVFhwTdTCPNSLy7MntQpqO4cJuw/WpBQ/nFtw7LbA7zvB4aYHfbQLpNMPnsuKdjv795f9KWiHLMBwMk+BIJJI1Cct8kUn4AT+zgSlNk2HaAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Card with an Image at the top",
                "title": "Card with an Image at the top",
                "src": "/static/0d73c20653948a54355af4a43a4147fc/50383/card-examples-media.png",
                "srcSet": ["/static/0d73c20653948a54355af4a43a4147fc/1efb2/card-examples-media.png 370w", "/static/0d73c20653948a54355af4a43a4147fc/50383/card-examples-media.png 740w", "/static/0d73c20653948a54355af4a43a4147fc/536c7/card-examples-media.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`A supporting visual can be used to make it easier to for users to identify a Card.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Images should match the topic." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/b6b095e90b471d65f9afbc524099a3f9/536c7/card-images-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABX0lEQVR42o2SXSuDARTHfQnlbl4ijG1NkwsyrbUaaUnJhdz4AC7mAiXJDUVeRoSsWKktteQCxc24WLaHZtYeb6PULjbhSnu256dnUt6Gf53O6XT+v86pk8cXSWmJiHjOSUjAf+LnMnbFS0ri+fGJu5tbhEAQISggSRI/Ke+9yGQy2SxeRDA0atBbqqm16HB5Ntje87HsXMfW1kxRhYpqgxpRjH7y5QSGo2HUDSp01lJau5oYHehlccXFsGOOGksZVcYSNCYtkcvIf4Hn6M1qdMZyTO31jPR3MzTQh+cgwJhzlSablo6eemL3F28++Q/gWTSEylBAYWUxVlsLU+N2hgbtzK95ce0EMHeaqDPmc3X9x4ayLGfzw2OS2ZUZJqYncW96OPLtcujb5/g0TEiM4d7ysrDkIJlIfPJ9A34EJxMPxONxckmZ+Qr6FZhOp7OnpFKp7HsoofSUUGqlnwv4CthTKJKPemiZAAAAAElFTkSuQmCC')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image paired with a matching title",
                    "title": "Image paired with a matching title",
                    "src": "/static/b6b095e90b471d65f9afbc524099a3f9/50383/card-images-do.png",
                    "srcSet": ["/static/b6b095e90b471d65f9afbc524099a3f9/1efb2/card-images-do.png 370w", "/static/b6b095e90b471d65f9afbc524099a3f9/50383/card-images-do.png 740w", "/static/b6b095e90b471d65f9afbc524099a3f9/536c7/card-images-do.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Avoid decorative imagery. Visuals should imply the subject of the card." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/e54f8ad4afb9a8f3997afd6761fd2f6d/536c7/card-images-don't.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABLUlEQVR42o2Ri0oCYRBGfdYeo54hgqAwSDTBQDMEjSSw0FoI8laUpuZ6CXXVXS+pu17Y9aSmUKLpB4f5YWYODL+JpRjGGKU1oD6h2tQmVaPZmdJHaWhUKio1WWM8HrMqpsVj0W91Bzj8ec79RU59KSz+NM5gAf+TiO8hi+suy2W4QLs//LO3QvjT6aoqzps0Nu8nZ1cZ3ME3Dk8yWB0FArkiF4kqjmeZRn+0nXA46nEtJLB4SxNZHuE+gN0exeyUcCcVzFaZY3MdpbelsKOpuEIf2EISNkHhNh7BI8TwPIY5OIqwtyOyv1uiXtt08rxqA52YWCOWl3mtDsl0NaS2hFyOk3oXiSZ7vMQ11C/9f+HvSJUyxZzIukyv2fjLy8NTdMOYo2PoOvoMY8Y64TfIMFrfwp3dnQAAAABJRU5ErkJggg==')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image paired with a non-matching title\"",
                    "title": "Image paired with a non-matching title\"",
                    "src": "/static/e54f8ad4afb9a8f3997afd6761fd2f6d/50383/card-images-don't.png",
                    "srcSet": ["/static/e54f8ad4afb9a8f3997afd6761fd2f6d/1efb2/card-images-don't.png 370w", "/static/e54f8ad4afb9a8f3997afd6761fd2f6d/50383/card-images-don't.png 740w", "/static/e54f8ad4afb9a8f3997afd6761fd2f6d/536c7/card-images-don't.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "layout-1"
        }}>{`Layout`}</h3>
        <h4 {...{
          "id": "carousels-1"
        }}>{`Carousels`}</h4>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Truncate header or body text for cards that should maintain a fixed height, such as a collection of cards in a carousel." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/1499dcb26a2c417a560915485c40242e/536c7/card-carousel-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "43.24324324324324%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABm0lEQVR42o2RTUsbURSG/UH+iK7ddtdNoVAoFfoDulFcFLqoUIq0UC0oVmtLSfEjCxVqUrrQShTJx5jRmUlmJvOlbWYmmQ8T8/SOi5KFmB54ec8997wPXO4YogaDQWZIkiQkc3RyRq3e5E/YE+oLXeN3YoK0jx/3iPqgGhaqqjKcz2pseHB6KlMsFVDLC1xqefzGOt1mjisjhyMf0djNYRe+cvlzg3L+E6qm3Q2sVGXKh8tcm+/EKQXzA1c/xnm1MMP+Vp7S0wc4z+9hzD5Gf/0EpdG8G6hLdaZ3vjCx/p4XJwVWz3+xrxWZmFNYWT3EePaQxdn7zKxNk7yd5GwUUBHA+dIej4ofmfy+wtTxDm/kA15u7bG5XUf//I2N/BxrB5sE20ucj35yFcd2SP2Qtu2RdLr/AmkS47V/Y7dckqCD7bkoijIaaDnOzbLtOhgtE/figrTXpxuneJ6HZVl4YlYTuyN/uVKpYmcBAbVMAcvgrRadMCSJEzzXxdT1m3upVvs/oGmYRN2IwA8I2j6h8HCozzyOIjQBuw34F61+k+3QoKfnAAAAAElFTkSuQmCC')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Truncate header or body text for cards that should maintain a fixed height, such as a collection of cards in a carousel.",
                    "title": "Truncate header or body text for cards that should maintain a fixed height, such as a collection of cards in a carousel.",
                    "src": "/static/1499dcb26a2c417a560915485c40242e/50383/card-carousel-do.png",
                    "srcSet": ["/static/1499dcb26a2c417a560915485c40242e/1efb2/card-carousel-do.png 370w", "/static/1499dcb26a2c417a560915485c40242e/50383/card-carousel-do.png 740w", "/static/1499dcb26a2c417a560915485c40242e/536c7/card-carousel-do.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Wrap text in Carousels. This will created a jagged baseline alignment." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/14bf5c3a3a11f2bd285b5968f2554425/536c7/card-carousel-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "43.24324324324324%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABoUlEQVR42o2RS2sTYRSG+4P8Ea67dedGEASx4A9wY+lCcGFBpChoBUVbq0iklyxawSbiorWkpSQ1zXSumWRm8nWSueUyk8vjVxfShTS+8HI4B96HczgzSE0mk4tCtVqVVjg8PuNXzaITD6VH0mPCpE+Ujgj7Q3oj0G0HXde5nL/QzOXB6alCsVRALy/jG3lCc52ulSOzc3jKIebXHG7hM/6PDcr5D+iGcTWwcqJQPnjHuPFCdik0XpN9v8aT5QX2tvKU7t3Ee3Ade/EO9ad30UzramC9WmN+5xOz6y95dFxgVf3JnlFkdkljZfUA+/4t3izeYGFtnsHzOc6mATUJfFXa5XbxPXPfVnh4tMMzZZ/HW7tsbteof/zCRn6Jtf1Nou23qNNPPsFzPdIwJnAFg6T7N5AO+oigg9tskUYJjmihadp0oON5iHOBJwR2symrBGTys70BoiVwnCbCP5cwdfqXK5UKjt3ANk0MVcVtNEjCkHGW0UsSoiAgaLfpxjG2Zf0f0LVtIhny5aa9KGKUpgzluVGnQ+C3/7gbJ6iK8k/gb+PslE6pmDfBAAAAAElFTkSuQmCC')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Wrap text when it will created a jagged bottom edge in a Carousel\"",
                    "title": "Wrap text when it will created a jagged bottom edge in a Carousel\"",
                    "src": "/static/14bf5c3a3a11f2bd285b5968f2554425/50383/card-carousel-dont.png",
                    "srcSet": ["/static/14bf5c3a3a11f2bd285b5968f2554425/1efb2/card-carousel-dont.png 370w", "/static/14bf5c3a3a11f2bd285b5968f2554425/50383/card-carousel-dont.png 740w", "/static/14bf5c3a3a11f2bd285b5968f2554425/536c7/card-carousel-dont.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h4 {...{
          "id": "vertical-stack-1"
        }}>{`Vertical Stack`}</h4>
        <p>{`When Cards are stacked on top of one another, prioritize Card content by letting text wrap instead
of truncating.`}</p>
        <p>{`In general, try to keep Card text under 3 lines of copy to avoid overly lengthy Cards.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Keep Cards descriptive and concise, ideally under 3 lines of text." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/653c6fa7848103542f33f05e506ee483/536c7/card-example-vstack-do.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAxklEQVR42pVRiwqDMBDz/z/SByr1/QCV1qqZuaHb3ITuIFxaSq7JebjUsiwwZoa1dscifdu2N+Dkv8q7XgzDAN8PoJRCGIZIkkREOYg4uLOg1hpVVQnSNEWe5yiKQjrvyrLch464qy/BaZoQBIEgiiLEcSycvz3OdOEsuK4r5nmW/GiNXGsjndk+uf3HshFbSmVomuZjEa+hm8BJcBwnyY72uJiua1HXNfq+R9u2e46l5OxsmY8pxF9yGRTPsuzkXI7llm8EH68OcJRyMf3LAAAAAElFTkSuQmCC')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "A Card with two lines of text as an example of what to do.",
                    "title": "A Card with two lines of text as an example of what to do.",
                    "src": "/static/653c6fa7848103542f33f05e506ee483/50383/card-example-vstack-do.png",
                    "srcSet": ["/static/653c6fa7848103542f33f05e506ee483/1efb2/card-example-vstack-do.png 370w", "/static/653c6fa7848103542f33f05e506ee483/50383/card-example-vstack-do.png 740w", "/static/653c6fa7848103542f33f05e506ee483/536c7/card-example-vstack-do.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Avoid using over 3 lines of text in a Card." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "740px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/7bfc0cc8f5839b824b820869eb2f2057/536c7/card-example-vstack-dont.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "37.83783783783784%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAz0lEQVR42pWSiQqDMBBE/f9fFA/EIyreJtEYnbpbFGor2IWQySKTtxMdXEophSRJUFUV8jxHWZbYtu3jGzpfe0c514aUCnEcQwiBMAxZ13XNFzRNw3ocJe7qy3CaJhRFgSzLkKYpGxMxGVOPiI0xuAH8RSjhui7TRVHEu+d5rIMgYPNlWZ4bav0mJDIhcta0KE/qtW37f4YHoe/7vBMZjUo5dl13PswjQ2st50g5zbPZiTVTr+t6GpF+bGj2fIZhRN/3+y+kz/HI5FjW3o/8AvB4buqgjMBPAAAAAElFTkSuQmCC')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "A Card with five lines of text as an example of what not to do.",
                    "title": "A Card with five lines of text as an example of what not to do.",
                    "src": "/static/7bfc0cc8f5839b824b820869eb2f2057/50383/card-example-vstack-dont.png",
                    "srcSet": ["/static/7bfc0cc8f5839b824b820869eb2f2057/1efb2/card-example-vstack-dont.png 370w", "/static/7bfc0cc8f5839b824b820869eb2f2057/50383/card-example-vstack-dont.png 740w", "/static/7bfc0cc8f5839b824b820869eb2f2057/536c7/card-example-vstack-dont.png 1480w"],
                    "sizes": "(max-width: 740px) 100vw, 740px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "overflow-actions-1"
        }}>{`Overflow Actions`}</h3>
        <p>{`Overflow menus contain related actions. They are typically placed in the upper-right or lower-right
corner of a card.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/536c7/card-example-overflow.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "37.83783783783784%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAe0lEQVR42qWPSw6AIBBDuf8ZYc8OFUfCtzommCjGiDZp2DBtn8CNSim7v0g0YS+OngrF3WetNZRSCCE0RbQ4DNPUsXALdM6BiJBSQs755BjjXvR6IYsPrLXw3h941XEr4dCuhRwmpYQxpkGeacEwdiIzan2vyNVdyH+0AgcjemkiCoPtAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Two cards vertically stacked. The first has an Overflow Menu placed at the end of the Header. The second has an Overflow Menu placed at the end of the Footer.",
                "title": "Two cards vertically stacked. The first has an Overflow Menu placed at the end of the Header. The second has an Overflow Menu placed at the end of the Footer.",
                "src": "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/50383/card-example-overflow.png",
                "srcSet": ["/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/1efb2/card-example-overflow.png 370w", "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/50383/card-example-overflow.png 740w", "/static/b5e96b4cf89cc6ed8a88531fd8cbe0f5/536c7/card-example-overflow.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "methods-1"
        }}>{`Methods`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`  fun CardUiComponent(
    modifier: Modifier = Modifier,
    contentModifier: Modifier = Modifier,
    contentArrangement: Arrangement.Vertical = Arrangement.spacedBy(WorkdayTheme.canvasSpace.x4),
    size: CardSize = CardSize.Medium,
    style: CardStyle = CardStyle.Elevated,
    mediaConfig: CardMediaConfig? = null,
    customMediaContent: @Composable (() -> Unit)? = null,
    headerConfig: CardHeaderConfig? = null,
    headerTitleTextStyle: TextStyle = WorkdayTheme.canvasTypography.bodyLarge.toBold700Weight(),
    headerSubtitleTextStyle: TextStyle = WorkdayTheme.canvasTypography.bodySmall,
    customHeaderContent: @Composable (() -> Unit)? = null,
    footerConfig: CardFooterConfig? = null,
    customFooterContent: @Composable (() -> Unit)? = null,
    overflowMenuConfig: CardOverflowMenuConfig? = null,
    onClick: (() -> Unit)? = null,
    interactionSource: MutableInteractionSource = remember { MutableInteractionSource() },
    semanticProperties: CardSemanticProperties = CardSemanticProperties(),
    content: @Composable (() -> Unit)? = null,
)
`}</code></pre>
        <h3 {...{
          "id": "parameters-1"
        }}>{`Parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`modifier`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Modifier for the whole component`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`contentModifier`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Modifier to be specifically used in the container holding the header, content, and footer slots. Regular modifier will affect the whole card container.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`contentArrangement`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Arrangement of the container holding the header, content, and footer slots. Defaults to spaced by.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Affects the padding used within the card.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`style`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Treatment of the card container.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`mediaConfig`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Media to be shown at the top of the card. Will take precedence over custom media content.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`customMediaContent`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Composable slot for any custom media to be shown at the top of the card.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`headerConfig`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Info to render a header between the media and content. Will take precedence over custom header content.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`headerTitleTextStyle`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Text style of the title text in the default header config.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`headerSubtitleTextStyle`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Text style of the subtitle text in the default header config.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`customHeaderContent`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Composable slot for a custom header rendered between the media and content.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`footerConfig`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Info to render a footer at the bottom of the card. Will take precedence over custom footer content.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`customFooterContent`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Composable slot for a custom footer rendered at the bottom of the card.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`overflowMenuConfig`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Where to position the overflow menu and what its content should be.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onClick`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Click action to perform when the card container is clicked.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`interactionSource`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional interaction source for the card container.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`semanticProperties`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optional information to customize the A11Y of the card component to match your feature's requirements.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`content`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Composable slot for content which is rendered between the header and footer.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Coming soon!`}</p>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      